import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Table, Empty } from "antd";

import { moneyFormatter } from "../../../../utils/moneyUtils";
import { dateFormatter } from "../../../../utils/dateUtils";

const DocumentsList = (props) => {
  const { t } = useTranslation();

  const [invoiceDocumentsList, setInvoiceDocumentsList] = useState([]);

  useEffect(() => {
    setInvoiceDocumentsList(props.invoiceDocumentsList);
  }, [props.invoiceDocumentsList]);

  const columns = [
    {
      title: t("invoiceUploadTableNumber"),
      dataIndex: "invoiceNumber",
      className: "invoiceNumber",
      sorter: invoiceDocumentsList?.length,
    },
    {
      title: t(
        props.contract?.factoringProduct !== "REVERSE"
          ? "invoiceUploadTableBuyer"
          : "invoiceUploadTableSupplier"
      ),
      dataIndex: "buyerName",
      className: "buyer",
      width: 150,
      render: (value) => (
        <div className="overflow">
          <span>{value}</span>
        </div>
      ),
      sorter: invoiceDocumentsList?.length,
    },
    {
      title: t("invoiceUploadTableIssueDate"),
      dataIndex: "issueDate",
      className: "invoiceDate",
      render: (value, item) => dateFormatter(value),
      sorter: invoiceDocumentsList?.length,
    },
    {
      title: t("invoiceUploadTableDueDate"),
      dataIndex: "dueDate",
      className: "invoiceDueDate",
      render: (value, item) => dateFormatter(value),
      sorter: invoiceDocumentsList?.length,
    },
    {
      title: t("invoiceUploadTableTransferClaim"),
      dataIndex: "transferClaimAmount",
      className: "transferClaim",
      render: (value, item) => moneyFormatter(value, item.currency),
      sorter: invoiceDocumentsList?.length,
    },
    {
      title: t("invoiceUploadTableInvoiceAmount"),
      dataIndex: "invoiceAmount",
      className: "invoiceAmount",
      render: (value, item) => moneyFormatter(value, item.currency),
      sorter: invoiceDocumentsList?.length,
    },
  ];

  const handleTableChange = async (pagination, filters, sorter) => {
    const sortFieldOrder = sorter.order
      ? sorter.field + "," + (sorter.order === "ascend" ? "ASC" : "DESC")
      : "";

    props.onInvoiceDocumentsListChanged(sortFieldOrder);
  };

  const tableLocale = {
    emptyText: (
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("noData")} />
    ),
  };

  return (
    <>
      <div className="invoice-verification__category__subtitle">
        {t("invoiceUploadBuyersInvoices")}
      </div>
      <div className="invoice-verification__list">
        <Table
          locale={tableLocale}
          columns={columns}
          rowKey={(record) => record.uuid}
          dataSource={invoiceDocumentsList}
          pagination={false}
          onChange={handleTableChange}
          summary={() => (
            <tr>
              <td colSpan="6">
                <strong>
                  <span> {t("total")} </span>
                  <span> {moneyFormatter(props.total, props.currency)} </span>
                </strong>
              </td>
            </tr>
          )}
        />
      </div>
    </>
  );
};

export default DocumentsList;
