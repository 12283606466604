import React from "react";
import { Tag } from "antd";

const InvoiceFileTag = (props) => {
  const log = async (e) => {
    if (props.remove) {
      props.remove(props.id);
    }

    e.preventDefault();
  };

  return (
    <>
      <Tag closable onClose={log}>
        <span>{props.fileName}</span>
        <span className="fileSize">
          {props.fileSize && props.fileSize.toLowerCase()}
        </span>
      </Tag>
    </>
  );
};

export default InvoiceFileTag;
