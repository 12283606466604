import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Row, Col, Modal, Button } from "antd";

import { CheckCircleOutlined } from "@ant-design/icons";

const SubmitForFinancingStatus = (props) => {
  const { t } = useTranslation();

  const [uploadStatusModalVisible, setUploadStatusModalVisible] = useState(
    false
  );

  const [uploadInvoiceStatus, setUploadInvoiceStatus] = useState(null);

  useEffect(() => {
    setUploadInvoiceStatus(props.status);
    setUploadStatusModalVisible(props.uploadStatusModalVisible);
  }, [props.uploadStatusModalVisible, props.status]);

  return (
    <>
      <Modal
        forceRender
        maskClosable={false}
        className={`uploadStatus submitForFinancingStatus ${
          uploadInvoiceStatus ? "success" : "error"
        }`}
        title={
          uploadInvoiceStatus
            ? t("invoicesSuccessfullySubmitted")
            : t("serviceIsTemporallyUnavailable")
        }
        visible={uploadStatusModalVisible}
        okText={t("submit")}
        cancelText={t("back")}
        onCancel={() => {
          setUploadStatusModalVisible(false);
        }}
        onOk={() => {
          props.submitForFinancing();
        }}
        okButtonProps={{
          className: "button button--primary button--large",
          style: { visibility: uploadInvoiceStatus ? "hidden" : "visible" },
        }}
        cancelButtonProps={{
          className: "button button--default button--large",
          style: { visibility: uploadInvoiceStatus ? "hidden" : "visible" },
        }}
      >
        {uploadInvoiceStatus && (
          <Row>
            <Col className="" span={22}>
              <CheckCircleOutlined />
              <div className="reviewInvoiceSuccessMessage">
                {t("reviewInvoiceSuccessMessage")}.
              </div>

              <div className="thankYouForChoosingMessage">
                {t("thankYouForChoosingMessage")}!
              </div>

              <Button
                className="closeLink"
                type="link"
                onClick={() => props.onRedirect()}
              >
                {t("closeWindow")}
              </Button>
            </Col>
          </Row>
        )}

        {!uploadInvoiceStatus && (
          <Row>
            <Col className="" span={22}>
              <div className="reviewInvoiceSuccessMessage">
                {t("invoicesUnsccessfullySubmitted")}
              </div>
            </Col>
          </Row>
        )}
      </Modal>
    </>
  );
};

export default SubmitForFinancingStatus;
