import React, { useState, useContext } from "react";
import { Row, Col, Upload, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { CloseOutlined } from "@ant-design/icons";

import Modal from "components/shared/Modal";
import { validateFiles, processFileValidationMessages, fileSizeIsValid } from "utils/fileUtils";
import { removeTitles } from "utils/helpers";
import { AuthContext } from "App";
import apiWrapper from "services/apiWrapper";
import { successNotification, errorNotification } from "utils/notificationUtils";

const AddAdditionalAttachments = (props) => {
  const { Dragger } = Upload;
  const { t } = useTranslation();
  const { dispatch } = useContext(AuthContext);

  const defaultAddAdditionalAttachmentsData = {
    files: [],
  };

  const [addAdditionalAttachmentsData, setAddAdditionalAttachmentsData] = useState(defaultAddAdditionalAttachmentsData);
  const [additionalAttachmentFiles, setAdditionalAttachmentFiles] = useState([]);

  const [loading, setLoading] = useState(false);
  const [fileUploadLoading, setFileUploadLoading] = useState(false);

  const spinConfiguration = {
    tip: t("loading") + "...",
  };

  const onChange = async (info, callback) => {
    const { status } = info.file;

    if (status === "done") {
      console.log(`${info.file.name} file uploaded successfully.`);
      callback(info);
    } else if (status === "error") {
      console.log(`${info.file.name} file upload failed.`);
    }
  };

  const draggerOnChange = async (info) => {
    const files = [...processFileValidationMessages(info, t)];

    setAdditionalAttachmentFiles(validateFiles(files));
    setFileUploadLoading(true);

    onChange(info, async function (response) {
      if (response.file) {
        const newAdditionalAttachmentsData = { ...addAdditionalAttachmentsData };
        newAdditionalAttachmentsData.files = [...addAdditionalAttachmentsData.files, response.file.response];

        setAddAdditionalAttachmentsData(newAdditionalAttachmentsData);
      }
    });

    setFileUploadLoading(false);
    removeTitles();
  };

  const draggerOnRemove = async (item) => {
    if (item.hasError) return;

    apiWrapper(await props.deleteAdditionalAttachmentFiles([item.response]), dispatch);

    const newAdditionalAttachmentsData = { ...addAdditionalAttachmentsData };
    newAdditionalAttachmentsData.files = newAdditionalAttachmentsData.files.filter((i) => i !== item.response);

    setAddAdditionalAttachmentsData(newAdditionalAttachmentsData);
  };

  const draggerBeforeUpload = (file, fileList) => {
    if (!fileSizeIsValid(file.size)) {
      file.validationText = t("fileSizeValidationMessage");
      file.hasError = true;
      setAdditionalAttachmentFiles([...fileList, file]);
      return false;
    }

    return true;
  };

  const modalOnOk = async () => {
    setLoading(true);

    const response = apiWrapper(
      await props.postStoreAdditionalAttachments({
        contract_id: props.contract?.id,
        file_upload_uuids: addAdditionalAttachmentsData.files,
      }),
      dispatch
    );

    if (response.error) {
      setLoading(false);
      errorNotification(t("error"), t("invoiceAdditionalAttachmentUploadError"), 10);
      return;
    }

    props.setAddAdditionalAttachmentsModalVisible(false);
    successNotification(t("success"), t("invoiceAdditionalAttachmentSuccessfullyUploaded"));

    setAddAdditionalAttachmentsData(defaultAddAdditionalAttachmentsData);
    setAdditionalAttachmentFiles([]);
    setLoading(false);
  };

  const modalOnCancel = async () => {
    props.setAddAdditionalAttachmentsModalVisible(false);

    apiWrapper(await props.deleteAdditionalAttachmentFiles(addAdditionalAttachmentsData?.files), dispatch);
    setAddAdditionalAttachmentsData(defaultAddAdditionalAttachmentsData);
    setAdditionalAttachmentFiles([]);
  };

  const uploadAdditionalAttachmentData = props.postAdditionalAttachmentData(props.contract?.id);

  return (
    <>
      <Modal
        width="48.125rem"
        className="increaseLimit contractFunction modal grey"
        title={t("invoiceAdditionalAttachmentTitle")}
        visible={props.addAdditionalAttachmentsModalVisible}
        okText={t("submit")}
        cancelText={t("cancel")}
        cancelButtonProps={{
          className: "button button--default button--large",
        }}
        okButtonProps={{
          className: "button button--primary button--large",
        }}
        onCancel={modalOnCancel}
        onOk={modalOnOk}
      >
        <Spin spinning={loading} tip={spinConfiguration.tip}>
          <Row>
            <Col className="uploadSupportingDocuments" span={24}>
              <p>{t("invoiceAdditionalAttachmentDescription")}</p>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Spin spinning={fileUploadLoading} tip={spinConfiguration.tip}>
                <Dragger
                  name="file"
                  multiple={true}
                  fileList={additionalAttachmentFiles}
                  beforeUpload={draggerBeforeUpload}
                  action={uploadAdditionalAttachmentData.action}
                  headers={uploadAdditionalAttachmentData.headers}
                  onChange={draggerOnChange}
                  onRemove={draggerOnRemove}
                  showUploadList={{
                    showRemoveIcon: true,
                    removeIcon: <CloseOutlined />,
                  }}
                >
                  <p className="ant-upload-drag-icon"></p>
                  <p className="ant-upload-text">+ {t("uploadFiles")}</p>
                  <p className="ant-upload-hint">{t("or")}</p>
                  <p className="ant-upload-hint">{t("dragDropSupportingFilesDescription")}</p>
                </Dragger>
              </Spin>
            </Col>
          </Row>
        </Spin>
      </Modal>
    </>
  );
};

export default AddAdditionalAttachments;
