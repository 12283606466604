import React from "react";
import GridEmpty from "./GridEmpty";

export const defaultEmptyText = "—";

export const tableLocale = {
  emptyText: <GridEmpty />,
};

export const sortContracts = (list) => {
  if (list) {
    list.sort((a, b) => (a.contractNumber > b.contractNumber ? 1 : -1));
  }

  return list;
};

export const processContracts = (contractList, selectedContracts, contractsIds) => {
  if (contractList?.length !== 0) {
    if (selectedContracts) {
      contractsIds = [...new Set([...contractsIds, ...selectedContracts.map((i) => i.id)])];
    } else {
      if (!contractsIds.length) {
        contractsIds.push(contractList[0].id);
      }
    }

    contractsIds = contractsIds.filter((i) => contractList.map((i) => i.id).includes(i));
  }

  return contractsIds;
};

export const urlProcessor = (urlParaters) => {
  if (urlParaters) {
    const searchParams = new URLSearchParams(urlParaters);

    const contractsIds = searchParams.get("contractId").split(",");
    const page = searchParams.get("page");
    const size = searchParams.get("size");

    const filter = {};

    for (const key of searchParams.keys()) {
      if (searchParams.get(key) !== "undefined" && key !== "contractId" && key !== "page" && key !== "size") {
        filter[key] = searchParams.get(key);
      }
    }

    return { contractsIds, page, size, filter };
  }

  return null;
};

export const getFilterParams = (filters) => {
  const params = [];
  Object.entries(filters).forEach(([key, val]) => {
    if (val) {
      params.push(`${key}=${val}`);
    }
  });
  if (params.length !== 0) {
    return `&${params.join("&")}`;
  }
  return "";
};

export const processFilterParams = (filter, filterKey, filterParameterKey, list) => {
  const filterParameters = [];

  Object.entries(filter).map(([key, val]) => {
    if (key.includes(filterKey)) {
      if (!!val) {
        filterParameters.push({ key: filterParameterKey, value: list[key] });
      }
    } else {
      filterParameters.push({ key: key, value: val });
    }
    return null;
  });

  return filterParameters;
};

export const pageSizes = ["10", "20", "30"];
export const defaultPageSize = parseInt(pageSizes[0]);

export const homePath = (productTypeName) => {
  return `/${productTypeName}/dashboard`;
};
