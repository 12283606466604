import React from "react";
import { useTranslation } from "react-i18next";

import Empty from "components/shared/Empty";

const GridEmpty = () => {
  const { t } = useTranslation();

  return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("noData")} />;
};

export default GridEmpty;
