import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ContractFilterSingle from "./contractFilterSingle";

const ContractSelector = (props) => {
  const [contractList, setContractList] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const filteredList = props.contractList.filter((item) => item.status !== "READY_FOR_SIGNING");
    setContractList(filteredList);
  }, [props.contractList]);

  return (
    <>
      <div className="block ContractSelector">
        {props.type !== "invoiceUpload" && <h4>{t("selectContract")}</h4>}
        <ContractFilterSingle
          selectedContract={props.selectedContract}
          contractList={contractList}
          contractSelected={props.contractSelected}
          type={props.type}
          productType={props.productType}
          productName={props.productName}
        />
      </div>
    </>
  );
};

export default ContractSelector;
