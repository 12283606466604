import React from "react";
import { Table } from "antd";

const CustomTable = ({ className, dataSource, ...props }) => (
  <Table
    className={`table ${!dataSource?.length && "table-empty"} ${className ? className : ""}`}
    dataSource={dataSource}
    {...props}
  />
);
export default CustomTable;
