import React from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Tabs } from "antd";

import ContractHeaderContent from "components/contractSelector/contractHeaderContent";

import UploadManually from "./uploadManually";
import UploadAutomatically from "./uploadAutomatically";
import UploadDocumentsList from "./uploadDocumentsList";

import UploadSummary from "./upload/uploadSummary";

const { TabPane } = Tabs;

const UploadDocuments = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="block selectedContract">
        <Row className={"onlySelectedContract"}>
          <ContractHeaderContent item={props.contract} type="no-factoring" />
        </Row>
      </div>
      <Row>
        <Col span="17">
          <div className="block uploadDocuments">
            <Tabs defaultActiveKey="1" animated={false}>
              <TabPane tab={t("uploadManually")} key="1">
                <UploadManually
                  contract={props.contract}
                  addUploadedItems={props.addUploadedItems}
                  postManualInvoiceUpload={props.postManualInvoiceUpload}
                  uploadedInvoices={props.uploadedInvoices}
                  onUploadManuallySuccess={props.onUploadManuallySuccess}
                  getUploadFilesManuallyData={props.getUploadFilesManuallyData}
                  productFid={props.productFid}
                />
              </TabPane>
              <TabPane tab={t("uploadAutomatically")} key="2">
                <UploadAutomatically
                  contract={props.contract}
                  addUploadedItems={props.addUploadedItems}
                  addAutomatiSupportedFiles={props.addAutomatiSupportedFiles}
                  getAutomaticInvoiceUploadRequestData={props.getAutomaticInvoiceUploadRequestData}
                  getUploadFilesManuallyData={props.getUploadFilesManuallyData}
                  productFid={props.productFid}
                />
              </TabPane>
            </Tabs>
          </div>
        </Col>
        <Col span="7">
          <div className="block uploadSummary">
            <UploadSummary uploadSummary={props.uploadInvoiceSummary} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <UploadDocumentsList
            contract={props.contract}
            addSelectedItems={props.addSelectedItems}
            invoiceDocumentsList={props.invoiceDocumentsList}
            onInvoiceDocumentsListChanged={props.onInvoiceDocumentsListChanged}
            automatiSupportedFiles={props.automatiSupportedFiles}
            deleteInvoiceUploadAttachmentFile={props.deleteInvoiceUploadAttachmentFile}
            uploadInvoiceStatus={props.uploadInvoiceStatus}
            openEditInvoice={props.openEditInvoice}
            setAddAdditionalAttachmentsModalVisible={props.setAddAdditionalAttachmentsModalVisible}
          />
        </Col>
      </Row>
    </>
  );
};

export default UploadDocuments;
