import React from "react";
import { useTranslation } from "react-i18next";

import Row from "components/shared/Row";
import Col from "components/shared/Col";
import TransactionList from "./transactionList";

const Transactions = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <h3>{t("invoiceDetailsTransactions")}</h3>
      <Row>
        <Col span={12}>
          <TransactionList
            list={props.invoicePayouts}
            totals={props.invoicePayoutsTotals}
            residual={props.invoiceAmountUnfunded}
            downloadPdfFile={props.downloadPdfFile}
            title={t("invoiceDetailsTransactionsPayouts")}
          />
        </Col>
        <Col span={12}>
          <TransactionList
            list={props.invoicePaymentReceipts}
            totals={props.invoicePaymentReceiptsTotals}
            residual={props.residualInvoiceAmount}
            downloadPdfFile={props.downloadPdfFile}
            title={t("invoiceDetailsTransactionsPaid")}
          />
        </Col>
      </Row>
    </>
  );
};

export default Transactions;
