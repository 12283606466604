import React, { useState } from "react";
import { FolderOpenOutlined, DownloadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { List } from "antd";

import Empty from "components/shared/Empty";
import Modal from "components/shared/Modal";
import Button from "components/shared/Button";

const ContractFiles = (props) => {
  const [contractFilesVisible, setContractFilesVisible] = useState(false);
  const { t } = useTranslation();

  const onDownloadFile = async (id) => {
    await props.downloadContractFile(id);
  };

  return (
    <div className="contract-files">
      <div className="contract-files__header">
        <FolderOpenOutlined className="folder-icon" />
        {!!props.contractFiles?.length && (
          <Button type="default" size="small" onClick={() => setContractFilesVisible(true)}>
            {t("more")}
          </Button>
        )}
      </div>
      <div className="contract-files__list">
        {props.contractFiles && (
          <List
            className="list"
            locale={{
              emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("noData")} />,
            }}
            grid={{ gutter: 16, column: 4 }}
            dataSource={props.contractFiles.filter((item, ind) => ind < 3)}
            renderItem={(item) => (
              <p>
                <span>{item.fileName}</span>
                <span>
                  <DownloadOutlined className="download-icon" onClick={() => onDownloadFile(item.id)} />
                </span>
              </p>
            )}
          />
        )}

        <Modal
          width="48.125rem"
          className="modal grey filesModal"
          title={t("contractFilesTitle")}
          visible={contractFilesVisible}
          okText={t("back")}
          cancelButtonProps={{
            className: "button button--default button--large",
            style: { visibility: "hidden" },
          }}
          okButtonProps={{
            className: "button button--primary button--large",
          }}
          onOk={() => {
            setContractFilesVisible(false);
          }}
        >
          <List
            locale={{
              emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("noData")} />,
            }}
            size="small"
            bordered
            dataSource={props.contractFiles}
            renderItem={(item) => (
              <List.Item key={item.id}>
                {item.fileName} <DownloadOutlined className="download-icon" onClick={() => onDownloadFile(item.id)} />
              </List.Item>
            )}
          />
        </Modal>
      </div>
    </div>
  );
};

export default ContractFiles;
