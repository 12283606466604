import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import settings from "config/config";
import cx from "classnames";

const DragAndDrop = ({ handleFileUpload, children }) => {
  const [draggedIn, setDraggedIn] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const dropRef = useRef();
  const inputRef = useRef();

  let dragCounter = 0;

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDraggedIn(true);
    }
  };
  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter--;
    if (dragCounter > 0) return;
    setDraggedIn(false);
  };
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDraggedIn(false);

    setUploadedFiles([...e.dataTransfer.files]);
  };
  const handleChange = (e) => {
    setUploadedFiles([...e.target.files]);
    inputRef.current.value = null;
  };

  useLayoutEffect(() => {
    const htmlElement = dropRef.current;

    htmlElement.addEventListener("dragenter", handleDragIn);
    htmlElement.addEventListener("dragleave", handleDragOut);
    htmlElement.addEventListener("dragover", handleDrag);
    htmlElement.addEventListener("drop", handleDrop);

    return () => {
      htmlElement.removeEventListener("dragenter", handleDragIn);
      htmlElement.removeEventListener("dragleave", handleDragOut);
      htmlElement.removeEventListener("dragover", handleDrag);
      htmlElement.removeEventListener("drop", handleDrop);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (uploadedFiles) handleFileUpload(uploadedFiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedFiles]);

  return (
    <>
      <input
        id="dragAndDropInput"
        type="file"
        ref={inputRef}
        onChange={handleChange}
        accept={settings.financialForm.fileUpload.mediaTypes}
        style={{ display: "none" }}
      />
      <div
        className={cx("dragAndDrop", {
          shakeOnDrag: draggedIn,
        })}
        ref={dropRef}
        onClick={() => inputRef.current.click()}
      >
        {children}
      </div>
    </>
  );
};

export default DragAndDrop;
