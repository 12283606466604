import React from "react";
import { useHistory } from "react-router-dom";

const Buyer = (props) => {
  let history = useHistory();

  const goBackToContract = () => {
    history.goBack();
  };

  return (
    <strong>
      <button onClick={goBackToContract}>Back</button>
    </strong>
  );
};

export default Buyer;
