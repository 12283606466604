import React from "react";
import { Row, Col, Button } from "antd";
import { useTranslation } from "react-i18next";

const InvoiceVerificationAction = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="invoice-verification-confirmation__actions">
        <Row>
          <Col span="12">
            <Button
              onClick={() => props.processSelected("ACCEPT")}
              type="primary"
              size="large"
            >
              {t("invoiceVerificationConfirm")}
            </Button>
            <div className="invoice-verification-confirmation__actions__note">
              {t("invoicinvoiceVerificationConfirmDescription")}
            </div>
          </Col>
          <Col span="12">
            <Button
              onClick={() => props.processSelected("REJECT")}
              type="default"
              size="large"
            >
              {t("invoiceVerificationReject")}
            </Button>
            <div className="invoice-verification-confirmation__actions__note">
              {t("invoiceVerificationRejectDescription")}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default InvoiceVerificationAction;
