import React, { useEffect, useState } from "react";
import { Form, Input, Select, Button, Row, Col } from "antd";
import DatePicker from "../shared/DatePicker";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { dateMomentFormatter } from "../../utils/dateUtils";

const { Option } = Select;

const InvoiceListFilter = (props) => {
  const { t } = useTranslation();
  const language = i18n.language;
  const [form] = Form.useForm();
  const [filteredInfo, setFilteredInfo] = useState(props.filteredInfo);
  const [buyerLabel, setBuyerLabel] = useState();
  const [invoicesStatusLabel, setInvoicesStatusLabel] = useState();

  useEffect(() => {
    setFilteredInfo(props.filteredInfo);
  }, [props.filteredInfo]);

  const getBuyerSupplierLabel = () => {
    const contracts = props?.contractList?.filter((i) => props?.selectedContracts?.includes(i.id));

    if (contracts?.every((i) => i.factoringProduct !== "REVERSE")) {
      return t("invoicesBuyer");
    }

    if (contracts?.every((i) => i.factoringProduct === "REVERSE")) {
      return t("invoicesSupplier");
    }

    return `${t("invoicesBuyer")}/${t("invoicesSupplier")}`;
  };

  useEffect(() => {
    setBuyerLabel(getBuyerSupplierLabel());
    setInvoicesStatusLabel(t("invoicesStatus"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  useEffect(() => {
    setBuyerLabel(getBuyerSupplierLabel());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.selectedContracts]);

  const invoiceStatuses = () => {
    let statuses = [
      { value: "SUBMITTED", text: t("submitted") },
      { value: "REJECTED", text: t("rejected") },
      { value: "FINANCED", text: t("financed") },
      { value: "OVERDUE", text: t("overdue") },
      { value: "PARTIALLY_PAID", text: t("partiallyPaid") },
      { value: "PAID", text: t("paid") },
      { value: "CLOSED", text: t("closed") },
      { value: "TRANSFERRED", text: t("transferred") },
    ];

    if (props.customInvoiceStatuses) {
      statuses.forEach((entry) => {
        const existingItem = Object.getOwnPropertyNames(props.customInvoiceStatuses).find((i) =>
          i.toLowerCase().includes(entry.value.toLowerCase())
        );

        if (existingItem) {
          entry.value = props.customInvoiceStatuses[existingItem];
        }
      });
    }

    return statuses;
  };

  useEffect(() => {
    setFilteredInfo(props.filteredInfo);
  }, [props.selectedContracts, props.filteredInfo]);

  const buyerSelected = (value) => {
    const newFilteredInfo = { ...filteredInfo, thirdPartyId: value };

    setFilteredInfo(newFilteredInfo);
    props.filterInfoChanged(newFilteredInfo);
  };

  const issueDateChangedFrom = (date, dateString) => {
    const newFilteredInfo = {
      ...filteredInfo,
      invoiceDateFrom: dateString,
    };

    setFilteredInfo(newFilteredInfo);
    props.filterInfoChanged(newFilteredInfo);
  };

  const issueDateChangedTo = (date, dateString) => {
    const newFilteredInfo = {
      ...filteredInfo,
      invoiceDateTo: dateString,
    };

    setFilteredInfo(newFilteredInfo);
    props.filterInfoChanged(newFilteredInfo);
  };

  const DueDateChangedFrom = (date, dateString) => {
    const newFilteredInfo = {
      ...filteredInfo,
      invoiceDueDateFrom: dateString,
    };

    setFilteredInfo(newFilteredInfo);
    props.filterInfoChanged(newFilteredInfo);
  };

  const DueDateChangedTo = (date, dateString) => {
    const newFilteredInfo = {
      ...filteredInfo,
      invoiceDueDateTo: dateString,
    };

    setFilteredInfo(newFilteredInfo);
    props.filterInfoChanged(newFilteredInfo);
  };

  const statusSelected = (value) => {
    const newFilteredInfo = { ...filteredInfo, invoiceStatus: value };

    setFilteredInfo(newFilteredInfo);
    props.filterInfoChanged(newFilteredInfo);
  };

  const onReset = () => {
    form.resetFields();
    filteredInfo.invoiceNumberFragment = "";
    setFilteredInfo({});
    props.filterInfoChanged({});
  };

  const numberValueChanged = (e) => {
    setFilteredInfo({ ...filteredInfo, invoiceNumberFragment: e.target.value });
  };

  const numberChangeHandler = (event) => {
    const newFilteredInfo = { ...filteredInfo, invoiceNumberFragment: event.target.value };
    setFilteredInfo(newFilteredInfo);
    props.filterInfoChanged(newFilteredInfo);
  };

  return (
    <div className="block InvoiceListFilter">
      <Form form={form}>
        <Row>
          <Col span={6} className={filteredInfo.thirdPartyId ? "filled" : ""}>
            <label>{filteredInfo.thirdPartyId ? getBuyerSupplierLabel() : "\u00A0"}</label>
            <Form.Item>
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={buyerSelected}
                placeholder={buyerLabel}
                defaultValue={buyerLabel}
                value={filteredInfo.thirdPartyId}
              >
                {props.buyersList.map((item, i) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col
            span={6}
            className={filteredInfo.invoiceNumberFragment != null ? "filled byNumberSearch" : "byNumberSearch"}
          >
            <label>{filteredInfo.invoiceNumberFragment ? t("invoicesNumber") : "\u00A0"}</label>
            <Form.Item>
              <Input.Search
                placeholder={t("invoicesNumber")}
                onSearch={numberChangeHandler}
                onInput={numberValueChanged}
                onChange={numberChangeHandler}
                value={filteredInfo.invoiceNumberFragment}
              />
            </Form.Item>
          </Col>
          <Col span={6} className={filteredInfo.invoiceDateFrom ? "filled" : ""}>
            <label>{filteredInfo.invoiceDateFrom ? t("invoicesIssueDateFrom") : "\u00A0"}</label>
            <DatePicker
              onChange={issueDateChangedFrom}
              placeholder={t("invoicesIssueDateFrom")}
              value={filteredInfo.invoiceDateFrom ? dateMomentFormatter(filteredInfo.invoiceDateFrom) : ""}
            />
          </Col>
          <Col span={6} className={filteredInfo.invoiceDateTo ? "filled" : ""}>
            <label>{filteredInfo.invoiceDateTo ? t("invoicesIssueDateTo") : "\u00A0"}</label>
            <DatePicker
              onChange={issueDateChangedTo}
              placeholder={t("invoicesIssueDateTo")}
              value={filteredInfo.invoiceDateTo ? dateMomentFormatter(filteredInfo.invoiceDateTo) : ""}
            />
          </Col>
        </Row>
        <Row>
          <Col span={6} className={filteredInfo.invoiceDueDateFrom ? "filled" : ""}>
            <label>{filteredInfo.invoiceDueDateFrom ? t("invoicesDueDateFrom") : "\u00A0"}</label>
            <DatePicker
              onChange={DueDateChangedFrom}
              placeholder={t("invoicesDueDateFrom")}
              value={filteredInfo.invoiceDueDateFrom ? dateMomentFormatter(filteredInfo.invoiceDueDateFrom) : ""}
            />
          </Col>
          <Col span={6} className={filteredInfo.invoiceDueDateTo ? "filled" : ""}>
            <label>{filteredInfo.invoiceDueDateTo ? t("invoicesDueDateTo") : "\u00A0"}</label>
            <DatePicker
              onChange={DueDateChangedTo}
              placeholder={t("invoicesDueDateTo")}
              value={filteredInfo.invoiceDueDateTo ? dateMomentFormatter(filteredInfo.invoiceDueDateTo) : ""}
            />
          </Col>
          <Col span={6} className={filteredInfo.invoiceStatus ? "filled" : ""}>
            <label>{filteredInfo.invoiceStatus ? t("invoicesStatus") : "\u00A0"}</label>
            <Select
              placeholder={invoicesStatusLabel}
              defaultValue={invoicesStatusLabel}
              onChange={statusSelected}
              value={filteredInfo.invoiceStatus}
            >
              {invoiceStatuses().map((item, i) => (
                <Option key={i} value={item.value}>
                  {item.text}
                </Option>
              ))}
            </Select>
          </Col>
          <Col className="form-control" span={6}>
            <Button onClick={onReset}> {t("clear")}</Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default InvoiceListFilter;
