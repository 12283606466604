import React, { useState, useEffect, useContext } from "react";
import { Form, Input, Button, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
import { defaultLabelText } from "utils/formUtils";
import LoadingSpin from "components/shared/LoadingSpin";
import { AuthContext } from "App";
import { productType, getActiveParty, getActiveProduct } from "utils/partiesUtils";
import {
  loginUser,
  getUser,
  getParties,
  getBoParty,
  verifyToken,
  changePassword,
  getCorrectLanguage,
} from "services/userService";

const PasswordChanger = () => {
  const { t } = useTranslation();
  const { dispatch } = useContext(AuthContext);
  const { token } = useParams();
  const [form] = Form.useForm();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const [labelText, setLabelText] = useState({
    changePasswordNewPassword: defaultLabelText,
    changePasswordRepeatPassword: defaultLabelText,
  });

  const errorText = {
    SME3: t("boServiceIsNotWorkingMessage"),
    SME12: t("noCompanyAssignedMessage"),
    default: t("leasingFormAccessValidationErrorServer"),
  };

  useEffect(() => {
    async function verifyAccess() {
      const response = await verifyToken(token);
      if (response.status === 404) {
        history.push("/404");
        return;
      }
      if (response.status === 401) {
        history.push("/expired");
        return;
      }
      setIsLoading(false);
    }
    verifyAccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const updateLabel = (name, value) => {
    setLabelText({ ...labelText, [name]: value ? t(name) : defaultLabelText });
  };

  const changePasswordAndLogin = async (data) => {
    const newPassword = data.changePasswordNewPassword;
    const response = await changePassword({
      passwordChangeToken: token,
      newPassword: newPassword,
    });
    if (!response.ok) {
      form.setFields([
        {
          name: "changePasswordNewPassword",
          errors: [errorText["default"]],
        },
      ]);
      return;
    }
    const email = response.payload;
    await login(email, newPassword);
  };

  const login = async (email, password) => {
    setIsLoggingIn(true);
    const loginResponse = await loginUser({ username: email, password: password });

    if (!loginResponse || loginResponse.error) {
      form.setFields([
        {
          name: "changePasswordNewPassword",
          errors: [errorText[loginResponse.errorCode] || errorText["default"]],
        },
      ]);
      setIsLoggingIn(false);
    } else {
      const userInfo = await getUser(loginResponse.token);

      loginResponse.user = {
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        accountId: userInfo.id,
        language: getCorrectLanguage(userInfo.language),
        country: userInfo.country,
        phone: userInfo.phoneNumber,
        email: userInfo.email,
        termsConditionsAccepted: userInfo.termsConditionsAccepted,
      };

      const userParties = await getParties(loginResponse.token);
      loginResponse.parties = userParties;

      const activeParty = getActiveParty(userParties);
      const activeProduct = getActiveProduct(activeParty);

      // party and product fid are equals, only because it's BO workaround
      const boParty = await getBoParty(
        activeProduct.type === productType.Factoring.toUpperCase() ? activeProduct?.fid : activeParty?.fid,
        loginResponse.token
      );

      loginResponse.assignedManager = boParty?.assignedManager;
      setIsLoggingIn(false);

      loginSuccess(loginResponse);
    }
  };

  const loginSuccess = (result) => {
    dispatch({
      type: "LOGIN",
      payload: result,
    });
    history.push("/");
  };

  const validatePassword = (value) => {
    const regexs = [/[a-z]/, /[A-Z]/, /[0-9]/, /[!@#$%^&*]/];
    let count = 0;
    for (let regex of regexs) {
      if (regex.test(value)) {
        count++;
      }
    }
    if (count >= 3) {
      return Promise.resolve();
    } else {
      return Promise.reject(t("changePasswordErrorPasswordDoesNotMatchCriteria"));
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpin />
      ) : (
        <div className="block loginForm resetPasswordConfirm">
          <div className="form-wrapper">
            <h1>{t("changePasswordTitle")}</h1>
            <p>{t("changePasswordDescription")}</p>
            <Spin spinning={isLoggingIn} tip={t("loading") + "..."}>
              <Form name="change_password" className="login-form" form={form} onFinish={changePasswordAndLogin}>
                <Form.Item
                  label={labelText["changePasswordNewPassword"]}
                  name="changePasswordNewPassword"
                  rules={[
                    {
                      required: true,
                      message: `${t("changePasswordNewPassword")} ${t("isRequired")}`,
                    },
                    {
                      min: 8,
                      message: t("changePasswordValueTooShort"),
                    },
                    {
                      validator: (_, value) => validatePassword(value),
                    },
                  ]}
                  extra={t("changePasswordNewPasswordDescription")
                    .split("</a>")
                    .map((item) =>
                      item
                        .split("<a>")
                        .map((innerItem, i) => (i === 1 ? <span key="{innerItem}">{innerItem}</span> : innerItem))
                    )}
                >
                  <Input.Password
                    autoComplete="new-password"
                    placeholder={t("changePasswordNewPassword")}
                    onChange={(event) => updateLabel("changePasswordNewPassword", event.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  label={labelText["changePasswordRepeatPassword"]}
                  name="changePasswordRepeatPassword"
                  dependencies={["changePasswordNewPassword"]}
                  rules={[
                    {
                      required: true,
                      message: `${t("changePasswordRepeatPassword")} ${t("isRequired")}`,
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("changePasswordNewPassword") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(t("changePasswordErrorPasswordsMustMatch"));
                      },
                    }),
                  ]}
                  extra={t("changePasswordErrorPasswordsMustMatch")}
                >
                  <Input.Password
                    autoComplete="new-password"
                    placeholder={t("changePasswordRepeatPassword")}
                    onChange={(event) => updateLabel("changePasswordRepeatPassword", event.target.value)}
                  />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    {t("changePasswordSubmitButton")}
                  </Button>
                </Form.Item>
              </Form>
            </Spin>
          </div>
        </div>
      )}
    </>
  );
};

export default PasswordChanger;
