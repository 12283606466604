import React from "react";
import createDOMPurify from "dompurify";

import { Form } from "antd";
import Checkbox from "components/shared/form/Checkbox";
import InputTooltip from "components/forms/formElements/InputTooltip";

const MarketingConsentBlock = ({ hidden = false, innerHtml, infoHtml = undefined, readOnly = false }) => {

  const DOMPurify = createDOMPurify(window);

  DOMPurify.addHook("afterSanitizeAttributes", (node) => {
    // set all elements owning target to target=_blank
    if ("target" in node) {
      node.setAttribute("target", "_blank");
      node.setAttribute("rel", "noopener");
    }
  });

  return (
    <>
      <Form.Item name={["applicant", "is_marketing_consent"]} valuePropName="checked" className={hidden ? "hidden" : ""}>
        <Checkbox className="public-form__checkbox" disabled={readOnly}>
          {innerHtml && (
            <span
              style={{ display: "inline-block" }}
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(innerHtml) }}
            ></span>
          )}
          {infoHtml && <InputTooltip innerHtml={DOMPurify.sanitize(infoHtml)} />}
        </Checkbox>
      </Form.Item>
    </>
  );
};

export default MarketingConsentBlock;