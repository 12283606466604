import React, { useState } from "react";
import { Form, Input, Button } from "antd";

import { useTranslation } from "react-i18next";

import { defaultLabelText } from "./../utils/formUtils";

const ResetPasswordConfirm = (props) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const [labelText, setLabelText] = useState({
    temporaryPassword: defaultLabelText,
    newPassword: defaultLabelText,
    repeatNewPassword: defaultLabelText,
  });

  const updateLabel = (name, value) => {
    setLabelText({ ...labelText, [name]: value ? t(name) : defaultLabelText });
  };

  const onFinish = (values) => {};

  return (
    <>
      <div className="block loginForm resetPasswordConfirm">
        <div className="form-wrapper">
          <h1>{t("resetPassword")}</h1>
          <p>{t("resetPasswordDescription")}</p>
          <Form
            name="reset_password"
            onFinish={onFinish}
            className="login-form"
            form={form}
          >
            <Form.Item
              label={labelText["temporaryPassword"]}
              name="temporaryPassword"
              rules={[
                {
                  required: true,
                  message: t("temporaryPassword") + " " + t("isRequired"),
                },
              ]}
            >
              <Input.Password
                placeholder={t("temporaryPassword")}
                onChange={(event) =>
                  updateLabel("temporaryPassword", event.target.value)
                }
              />
            </Form.Item>

            <Form.Item
              label={labelText["newPassword"]}
              name="newPassword"
              rules={[
                {
                  required: true,
                  message: t("newPassword") + " " + t("isRequired"),
                },
              ]}
              extra={t("newPasswordDescription")
                .split("<a>")
                .map((item, i) =>
                  item.includes("</a>")
                    ? item
                        .split("</a>")
                        .map((itemInternal, i) =>
                          i === 0 ? (
                            <span>{itemInternal.replace("</a>", "")}</span>
                          ) : (
                            itemInternal
                          )
                        )
                    : item
                )}
            >
              <Input.Password
                placeholder={t("newPassword")}
                onChange={(event) =>
                  updateLabel("newPassword", event.target.value)
                }
              />
            </Form.Item>
            <Form.Item
              label={labelText["repeatNewPassword"]}
              name="repeatNewPassword"
              rules={[
                {
                  required: true,
                  message: t("repeatNewPassword") + " " + t("isRequired"),
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(t("passwordMustMatch"));
                  },
                }),
              ]}
              extra={t("passwordMustMatch")}
            >
              <Input.Password
                placeholder={t("repeatNewPassword")}
                onChange={(event) =>
                  updateLabel("repeatNewPassword", event.target.value)
                }
              />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                {t("resetPassword")}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default ResetPasswordConfirm;
