import React, { useState, useEffect } from "react";
import { Form, Input, Slider, Row, Col } from "antd";

import { stripStringToDecimal } from "utils/formUtils";

const InputSlider = ({
  type,
  inputName,
  inputSuffix = " ",
  value,
  rules,
  label = " ",
  onChange = () => undefined,
  normalize,
  sliderMin,
  sliderMax,
  sliderMinMaxSuffix = " ",
  sliderMarks,
}) => {
  const hasDecimalMask = type === "decimal";

  const [currentValue, setCurrentValue] = useState(value);
  const [inputValueChanged, setInputValueChanged] = useState(false);
  const [sliderValueChanged, setSliderValueChanged] = useState(false);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const onInputChange = (val) => {
    if (currentValue === val) {
      return;
    }

    setCurrentValue(val);
    setInputValueChanged(true);
  };

  const onInputBlur = () => {
    if (!inputValueChanged) {
      return;
    }

    inputValueChanged && onChange(currentValue); // propagate up
    setInputValueChanged(false);
  };

  const onSliderChange = (val) => {
    if (currentValue === val) {
      return;
    }

    currentValue && setSliderValueChanged(true);
    setCurrentValue(val);
  };

  const onSliderAfterChange = () => {
    onInputChange(currentValue);

    sliderValueChanged && onChange(currentValue);
    setSliderValueChanged(false);
  };

  const normalizeWraper = (...args) => {
    if (hasDecimalMask) {
      return (normalize && normalize(stripStringToDecimal(args[0]), ...args.slice(1))) || stripStringToDecimal(args[0]);
    }
    return (normalize && normalize(...args)) || args[0];
  };

  const getSliderRange = (val) => {
    return val.reduce((result, item) => {
      result[item] = "";
      return result;
    }, {});
  };

  const getSliderStep = () => {
    if (sliderMarks) {
      return null;
    }

    // test carefully before change it
    return 100;
  };

  const getSliderMin = () => {
    if (sliderMarks) {
      return sliderMin;
    }

    // antd slider has a bug with min max
    const step = getSliderStep();
    return Math.floor(sliderMin / step) * step;
  };

  const getSliderMax = () => {
    if (sliderMarks) {
      return sliderMax;
    }

    // antd slider has a bug with min max
    const step = getSliderStep();
    return Math.ceil(sliderMax / step) * step;
  };

  const getSliderMarks = () => {
    return sliderMarks ? getSliderRange(sliderMarks) : undefined;
  };

  return (
    <>
      <Form.Item label={label} name={inputName} rules={rules} normalize={normalizeWraper}>
        <Input
          onChange={(event) => onInputChange(parseInt(event.target.value))}
          onBlur={() => onInputBlur()}
          value={currentValue}
          suffix={inputSuffix ? inputSuffix : <span />}
          autoComplete="off"
        />
      </Form.Item>
      <Form.Item className="slider">
        <Slider
          onChange={onSliderChange}
          onAfterChange={onSliderAfterChange}
          value={currentValue}
          tooltipVisible={false}
          min={getSliderMin()}
          max={getSliderMax()}
          step={getSliderStep()}
          marks={getSliderMarks()}
        />
      </Form.Item>
      <Row className="min-max">
        <Col className="min-value" span={8}>
          {!isNaN(sliderMin) ? sliderMin + sliderMinMaxSuffix : <span />}
        </Col>
        <Col className="max-value" span={8} offset={8}>
          {!isNaN(sliderMax) ? sliderMax + sliderMinMaxSuffix : <span />}
        </Col>
      </Row>
    </>
  );
};

export default InputSlider;
