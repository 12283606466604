import React, { useEffect, useState } from "react";
import { Form, Collapse, Col } from "antd";
import IconArrow from "../../assets/icons/icon_arrow.svg";
import cx from "classnames";
import Button from "../shared/Button";
import NewBuyerForm from "./newBuyerForm";
import { useTranslation } from "react-i18next";

const { Panel } = Collapse;

const NewBuyerFormPanel = ({
  countries,
  countriesWithPhoneCodes,
  form,
  isBuyer,
  formErrors,
}) => {
  const { t } = useTranslation();
  const [panelStatus, setPanelStatus] = useState("0");
  const [panelCount, setPanelCount] = useState(0);
  const [activeKey, setActiveKey] = useState("0");
  const [panelHeaders, setPanelHeaders] = useState([
    {
      index: 0,
      hide: false,
      buyer: "",
      limit: "",
    },
  ]);

  useEffect(() => {
    setActiveKey(panelStatus);

    const newState = panelHeaders.map((panel) => {
      if (panelStatus && parseInt(panelStatus) === panel.index) {
        panel.hide = true;
      } else {
        panel.hide = false;
      }
      return panel;
    });

    setPanelHeaders(newState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panelStatus]);

  const renderHeader = (panel) => {
    return (
      <div className="panel-header">
        <div className="panel-header__item">
          <label className="panel-header__label">
            {isBuyer ? t("buyer") : t("supplier")}
          </label>
          <span className="panel-header__text">
            {panel.buyer === "" || !panel.buyer ? "-" : panel.buyer}
          </span>
        </div>
        <div className="panel-header__item">
          <label className="panel-header__label">{t("limit")}</label>
          <span className="panel-header__text">
            {panel.limit === "" || !panel.limit ? "-" : panel.limit}
          </span>
        </div>
      </div>
    );
  };

  const addPanel = (add) => {
    setPanelHeaders([...panelHeaders, { index: panelCount + 1, hide: false }]);

    setPanelStatus(panelCount + 1);
    setActiveKey(panelCount + 1);
    setPanelCount(panelCount + 1);
    add();
  };

  return (
    <Form.List name="buyers">
      {(fields, { add, remove }) => {
        return (
          <Collapse
            expandIconPosition="right"
            defaultActiveKey="0"
            activeKey={activeKey}
            accordion
            onChange={(value) => {
              setPanelStatus(value);
            }}
            expandIcon={({ isActive }) => (
              <Button
                icon={
                  <img
                    src={IconArrow}
                    shape="round"
                    alt="arrow"
                    className={cx("faq-page__arrow-icon", {
                      "faq-page__arrow-icon--active": isActive,
                    })}
                  />
                }
              />
            )}
          >
            {fields.map((field) => {
              const panelHeader = panelHeaders.find(
                (panel) => panel.index === field.key
              );

              return (
                <Panel
                  key={field.key}
                  header={!panelHeader.hide ? renderHeader(panelHeader) : null}
                  className={cx({
                    error: formErrors.includes(field.key),
                  })}
                >
                  <Col span={24}>
                    <NewBuyerForm
                      add={add}
                      setPanelHeaders={setPanelHeaders}
                      panelHeaders={panelHeaders}
                      remove={remove}
                      field={field}
                      countries={countries}
                      countriesWithPhoneCodes={countriesWithPhoneCodes}
                      form={form}
                      isBuyer={isBuyer}
                      addPanel={addPanel}
                    />
                  </Col>
                </Panel>
              );
            })}
          </Collapse>
        );
      }}
    </Form.List>
  );
};

export default NewBuyerFormPanel;
