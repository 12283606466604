import React from "react";
import { useTranslation } from "react-i18next";

import Input from "components/shared/form/Input";

const Email = ({ name, label, readOnly = false }) => {
  const { t } = useTranslation();

  return (
    <>
      <Input
        label={label}
        name={name}
        rules={[
          {
            type: "email",
            required: true,
            message: label + " " + t("isRequired"),
          },
          {
            max: 80,
            message: t("g4rFormFieldValueTooLong"),
          },
        ]}
        disabled={readOnly}
      />
    </>
  );
};

export default Email;
