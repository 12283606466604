import React, { useEffect, useState } from "react";
import i18n from "i18next";
import WaveChartControls from "./waveChartControls";
import { useTranslation } from "react-i18next";

import Recharts from "./waveChartRecharts";

import { moneyFormatter } from "./../../utils/moneyUtils";
import { waveStepTypes } from "../../utils/statusUtils";

import moment from "moment";
import "moment/locale/lt";
import "moment/locale/lv";
import "moment/locale/et";

const getMinMax = (type, data, key) => {
  if (data.length === 0) return 0;
  return Math[type].apply(
    Math,
    data.map((d) => d[key])
  );
};

const WaveChart = (props) => {
  const { t } = useTranslation();

  const [data, setData] = useState({ chartData: [] });

  const formatLabel = (date, amount) => {
    return [
      moment(date)
        .locale(i18n.language)
        .format(props.currentStepType === waveStepTypes.Month ? "MMMM, YYYY" : "D, MMMM"),
      `${t("used")}: ${moneyFormatter(amount, props.data?.currency).replace(",00", "")}`,
    ];
  };

  const formatChartValues = (values) => {
    const formatValue = ({ date, amount }, type) => ({
      y: amount,
      x: parseInt(moment(date).format(type), 10),
      label: formatLabel(date, amount),
    });

    switch (props.currentStepType) {
      case waveStepTypes.Month:
        return values
          .filter((valItem) =>
            moment(valItem.date).isBetween(
              moment(props.currentWaveDateItem).subtract(12, "months"),
              props.currentWaveDateItem,
              "month",
              "(]"
            )
          )
          .map((valItem) => formatValue(valItem, "M"));
      case waveStepTypes.Day:
        return values
          .filter((valItem) => moment(valItem.date).isSame(props.currentWaveDateItem, "month"))
          .map((valItem) => formatValue(valItem, "D"));
      default:
        return [];
    }
  };

  useEffect(() => {
    if (props.data) {
      const { history } = props.data;
      if (history && history.length) {
        const chartData = formatChartValues(history || []);
        const smallestAllTime = getMinMax("min", history, "amount");
        const largestAllTime = getMinMax("max", history, "amount");
        const smallestInRange = getMinMax("min", chartData, "y");
        const largestInRange = getMinMax("max", chartData, "y");
        setData({
          chartData,
          smallestInRange,
          smallestAllTime,
          largestInRange,
          largestAllTime,
          noData: chartData.length === 0,
        });
      } else {
        setData({ ...data, noData: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, props.currentWaveDateItem, i18n.language]);

  return (
    <div className={data.noData ? "chart-no-data" : undefined}>
      <WaveChartControls
        updateCurrentStepType={props.updateCurrentStepType}
        currentStepType={props.currentStepType}
        currentWaveDateItem={props.currentWaveDateItem}
        history={props.data?.history || []}
        changeCurrentWaveDateItem={props.changeCurrentWaveDateItem}
        currentStepTitle={props.currentStepTitle}
      />
      <div className="rechart-wrap">
        <Recharts
          onChartClick={props.onChartClick}
          currentStepType={props.currentStepType}
          data={data}
          currency={props.data?.currency}
        />
      </div>
    </div>
  );
};

export default WaveChart;
