import React, { useEffect, useRef } from "react";
import { Radio } from "antd";
import cx from "classnames";

const CustomRadio = ({ onChange = () => undefined, className, ...args }) => {
  const radioRef = useRef();

  useEffect(() => {
    if (radioRef.current.props.value) {
      onChange(radioRef.current.props.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Radio.Group ref={radioRef} onChange={(e) => onChange(e.target.value)} className={cx("radio-group", className)} {...args} />;
};

export default CustomRadio;
