import React from "react";
import createDOMPurify from "dompurify";

import { Form } from "antd";
import Row from "components/shared/Row";
import Col from "components/shared/Col";
import Checkbox from "components/shared/form/Checkbox";

const DataProcessingConsentBlock = ({ consent, onConsentChange = () => undefined, innerHtml, readOnly }) => {
  const DOMPurify = createDOMPurify(window);

  DOMPurify.addHook("afterSanitizeAttributes", (node) => {
    // set all elements owning target to target=_blank
    if ("target" in node) {
      node.setAttribute("target", "_blank");
      node.setAttribute("rel", "noopener");
    }
  });

  return (
    <Row>
      <Col span={24}>
        <Form.Item name={["applicant", "is_consent_of_data_processing_agreed"]} valuePropName="checked">
          <Checkbox
            onChange={onConsentChange}
            checked={consent}
            className="public-form__checkbox"
            disabled={readOnly}
          >
            <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(innerHtml) }}></span>
          </Checkbox>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default DataProcessingConsentBlock;
