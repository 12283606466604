import moment from "moment";
import settings from "../config/config";

export const isToday = (dateString) => {
  const today = new Date();
  const date = new Date(dateString);
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

export const dateFormatter = (date) => {
  return date ? new Date(date).toISOString().split("T")[0] : date;
};

export const dateMomentFormatter = (date) => {
  return date ? moment(date, settings.dateFormat) : "";
};

export const dateIsLater = (date1, date2) => {
  return moment(date1).diff(!date2 ? moment() : moment(date2)) >= 0;
};

export const dateIsLaterThanToday = (date) => {
  return date > moment();
};

export const dateIsEarlierThanYesterday = (date) => {
  return date < moment().subtract(1, "day");
};

export const dateIsEarlierThanToday = (date) => {
  return date < moment();
};
