import React from "react";
import createDOMPurify from "dompurify";

import { Form } from "antd";
import Row from "components/shared/Row";
import Col from "components/shared/Col";
import Checkbox from "components/shared/form/Checkbox";

const RentConsentBlock = ({ readOnly, innerHtml }) => {
  const DOMPurify = createDOMPurify(window);

  DOMPurify.addHook("afterSanitizeAttributes", (node) => {
    // set all elements owning target to target=_blank
    if ("target" in node) {
      node.setAttribute("target", "_blank");
      node.setAttribute("rel", "noopener");
    }
  });

  return (
    <>
      <Row className="margin-top-1rem">
        <Col span={24}>
          <Form.Item name={["lending", "is_rent_consent"]} valuePropName="checked">
            <Checkbox className="public-form__checkbox" disabled={readOnly}>
              <span
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(innerHtml),
                }}
              ></span>
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default RentConsentBlock;
