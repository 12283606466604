import React from "react";

import { Form, Input } from "antd";

const HiddenInput = ({ name, value }) => {
  return (
    <Form.Item name={name} className={"hidden"} >
      <Input value={value} disabled/>
    </Form.Item>
  );
};

export default HiddenInput;
