import React from "react";
import { Form } from "antd";

const CustomForm = (props) => {
  const [form] = Form.useForm();

  // const resetFields = () => {
  //   form.resetFields();
  // };

  return (
    <Form className="form" {...props} form={form}>
      {props.children}
    </Form>
  );
};

export default CustomForm;
