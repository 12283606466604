import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Table, Empty, Row } from "antd";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import Button from "../shared/Button";
import UploadDocumentDetails from "./uploadDocumentDetails";

import SupportingFilesList from "./upload/supportingFilesList";

import { moneyFormatter } from "../../utils/moneyUtils";
import { dateFormatter } from "../../utils/dateUtils";

const UploadDocumentsList = (props) => {
  const { t } = useTranslation();

  const [invoiceDocumentsList, setInvoiceDocumentsList] = useState([]);

  const [expandedKeys, setExpandedKeys] = useState([]);

  useEffect(() => {
    setInvoiceDocumentsList(props.invoiceDocumentsList);
  }, [props.invoiceDocumentsList]);

  const columns = [
    {
      title: t("invoiceUploadTableNumber"),
      dataIndex: "invoiceNumber",
      className: "invoiceNumber",
      sorter: invoiceDocumentsList?.length,
    },
    {
      title: t(
        props.contract?.factoringProduct !== "REVERSE"
          ? "invoiceUploadTableBuyer"
          : "invoiceUploadTableSupplier"
      ),
      dataIndex: "buyerName",
      className: "buyer",
      width: 150,
      render: (value) => (
        <div className="overflow">
          <span>{value}</span>
        </div>
      ),
      sorter: invoiceDocumentsList?.length,
    },
    {
      title: t(
        props.contract?.factoringProduct !== "REVERSE"
          ? "invoiceUploadTableBuyerId"
          : "invoiceUploadTableSupplierId"
      ),
      dataIndex: "buyerCode",
      className: "buyerCode",
      sorter: invoiceDocumentsList?.length,
    },
    {
      title: t("invoiceUploadTableIssueDate"),
      dataIndex: "issueDate",
      className: "invoiceDate",
      render: (value, item) => dateFormatter(value),
      sorter: invoiceDocumentsList?.length,
    },
    {
      title: t("invoiceUploadTableDueDate"),
      dataIndex: "dueDate",
      className: "invoiceDueDate",
      render: (value, item) => dateFormatter(value),
      sorter: invoiceDocumentsList?.length,
    },
    {
      title: t("invoiceUploadTableTransferClaim"),
      dataIndex: "transferClaimAmount",
      className: "transferClaim",
      render: (value, item) => moneyFormatter(value, item.currency),
      sorter: invoiceDocumentsList?.length,
    },
    {
      title: t("invoiceUploadTableInvoiceAmount"),
      dataIndex: "invoiceAmount",
      className: "invoiceAmount",
      render: (value, item) => moneyFormatter(value, item.currency),
      sorter: invoiceDocumentsList?.length,
    },
    {
      title: "",
      dataIndex: "edit",
      className: "edit-button-row",
      render: (text, record) => (
        <Button
          type="default"
          size="small"
          onClick={() => props.openEditInvoice(record)}
        >
          {t("invoiceUploadEdit")}
        </Button>
      ),
    },
    {
      title: "",
      dataIndex: "error",
      className: "error-message",
      render: (text, record) => {
        const invoiceStatus = props.uploadInvoiceStatus;

        if (invoiceStatus?.data?.errors.length > 0) {
          const error = invoiceStatus.data.errors.filter(
            (error) => error.invoiceNumber === record.invoiceNumber
          );

          if (error.length > 0) {
            return <p>{t(error[0].errorCode)}</p>;
          }
        }

        return null;
      },
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      props.addSelectedItems(selectedRows);
    },
    getCheckboxProps: (record) => ({
      name: record.name,
    }),
  };

  const onRow = (record, rowIndex) => {
    const invoiceStatus = props.uploadInvoiceStatus;

    if (invoiceStatus?.data?.errors.length > 0) {
      const error = invoiceStatus.data.errors.filter(
        (error) => error.invoiceNumber === record.invoiceNumber
      );
      if (error.length > 0) {
        return { className: "error" };
      }
    }

    return expandedKeys.includes(record.id) && { className: "expand-parent" };
  };

  const handleTableChange = async (pagination, filters, sorter) => {
    const sortFieldOrder = sorter.order
      ? sorter.field + "," + (sorter.order === "ascend" ? "ASC" : "DESC")
      : "";

    props.onInvoiceDocumentsListChanged(sortFieldOrder);
  };

  const onExpand = (expanded, record) => {
    const expandedKeysNew = expanded
      ? expandedKeys.concat(record.id)
      : expandedKeys.filter((k) => k !== record.id);
    setExpandedKeys(expandedKeysNew);
  };

  const tableLocale = {
    emptyText: (
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("noData")} />
    ),
  };

  let invoiceSavedStyle = "";

  props.invoiceDocumentsList.length > 0
    ? (invoiceSavedStyle = "invoicesSaved")
    : (invoiceSavedStyle = "invoicesSaved hiden");

  return (
    <>
      {
        <div className="block uploadDocumentList">
          <Row className={invoiceSavedStyle}>{t("invoicesSavedLabel")}</Row>
          <Table
            locale={tableLocale}
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
            }}
            columns={columns}
            rowKey={(record) => record.uuid}
            dataSource={invoiceDocumentsList}
            onRow={onRow}
            onExpand={onExpand}
            expandedRowKeys={expandedKeys}
            pagination={false}
            onChange={handleTableChange}
            expandable={{
              rowExpandable: (record) => record.type === "manual",
              expandedRowRender: (record) => (
                <UploadDocumentDetails
                  invoiceFiles={record.invoiceFiles}
                  supportingDocuments={record.supportingDocuments}
                />
              ),
              expandIcon: ({ expanded, onExpand, record }) =>
                record.type === "manual" ? (
                  expanded ? (
                    <UpOutlined
                      onClick={(e) => {
                        onExpand(record, e);
                      }}
                    />
                  ) : (
                    <DownOutlined onClick={(e) => onExpand(record, e)} />
                  )
                ) : null,
              expandIconColumnIndex: 7,
            }}
          />
          <SupportingFilesList
            supportingFiles={props.automatiSupportedFiles}
            deleteInvoiceUploadAttachmentFile={
              props.deleteInvoiceUploadAttachmentFile
            }
            setAddAdditionalAttachmentsModalVisible={props.setAddAdditionalAttachmentsModalVisible}
          />
        </div>
      }
    </>
  );
};

export default UploadDocumentsList;
