import React from "react";
import PropTypes from "prop-types";
import { Modal } from "antd";
import Button from "./Button";
import closeIcon from "assets/icons/icon_close.svg";

const CustomModal = ({
  cancelText,
  okText,
  onCancel,
  onOk,
  onClickClose,
  okButtonProps,
  cancelButtonProps,
  children,
  ...props
}) => (
  <Modal
    maskClosable={false}
    closable={false}
    closeIcon={<img src={closeIcon} alt="close" onClick={onClickClose} />}
    destroyOnClose={true}
    forceRender
    footer={
      <>
        <Button type="default" size="large" {...cancelButtonProps} onClick={onCancel}>
          {cancelText}
        </Button>
        <Button type="primary" size="large" {...okButtonProps} onClick={onOk}>
          {okText}
        </Button>
      </>
    }
    {...props}
  >
    {children}
  </Modal>
);

CustomModal.propTypes = {
  cancelText: PropTypes.string,
  okText: PropTypes.string,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  okButtonProps: PropTypes.object,
  cancelButtonProps: PropTypes.object,
};

export default CustomModal;
