import React from "react";
import Modal from "components/shared/Modal";

import closeIcon from "assets/icons/icon_close.svg";

const DokobitModal = ({ url, onClose = () => undefined, onLoad = () => undefined, visible = true }) =>
  url && (
    <Modal
      width="65rem"
      className="modal grey contract-sign__modal contract-sign__modal--dokobit"
      visible={visible}
      closeIcon={<img src={closeIcon} alt="close" onClick={onClose} />}
      footer={null}
      closable={true}
      destroyOnClose={true}
      forceRender={false} // TODO: without this proper render fails, need to investigate, lib versions?
    >
      <iframe frameBorder="none" title="dokobit" src={url} onLoad={onLoad} />
    </Modal>
  );

export default DokobitModal;
