import React from "react";
import { useTranslation } from "react-i18next";
import { Input, Form, Select } from "antd";
import { defaultLabelText } from "../../../utils/formUtils";
import { filterArrOfObject } from "../../../utils/helpers";

const PhoneInput = ({
  countriesPhoneCodes = [],
  phoneText = null,
  setPhoneText = null,
  placeholder = "",
  form,
  name = "phoneNumber",
  prefixName = "phoneCode",
  required,
}) => {
  const { t } = useTranslation();
  const { Option } = Select;

  const prefixSelector = (
    <Form.Item name={prefixName} noStyle>
      <Select
        className="phone-code"
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        {countriesPhoneCodes.length &&
          filterArrOfObject(countriesPhoneCodes, "dial_code").map((item, i) => (
            <Option key={i} value={item.dial_code}>
              {item.dial_code}
            </Option>
          ))}
      </Select>
    </Form.Item>
  );
  return (
    <Form.Item
      label={phoneText ? phoneText : defaultLabelText}
      className="phone-input"
      name={name}
      rules={[
        {
          required: required,
          message: `${t("phone")} ${t("isRequired")}`,
        },
      ]}
    >
      <Input
        onChange={(event) => {
          const { value } = event.target;
          const reg = /^-?\d*(\.\d*)?$/;

          if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
            form.setFieldsValue({
              [name]: value,
            });

            if (phoneText && setPhoneText) {
              setPhoneText(event.target.value ? placeholder : defaultLabelText);
            }
          } else {
            form.setFieldsValue({
              [name]: "",
            });
          }
        }}
        addonBefore={prefixSelector}
        placeholder={placeholder}
      />
    </Form.Item>
  );
};

export default PhoneInput;
