const locale = {
  lang: {
    placeholder: "Izvēlieties datumu",
    yearPlaceholder: "Atlasiet gadu",
    quarterPlaceholder: "Atlasiet ceturksni",
    monthPlaceholder: "Atlasiet mēnesi",
    weekPlaceholder: "Atlasiet nedēļu",
    rangePlaceholder: ["Sākuma datums", "Beigu datums"],
    rangeYearPlaceholder: ["Sākuma gads", "Beigu gads"],
    rangeMonthPlaceholder: ["Sākuma mēnesis", "Mēneša beigas"],
    rangeWeekPlaceholder: ["Sākt nedēļu", "Nedēļas beigas"],
    locale: "lv_LV",
    today: "Šodien",
    now: "Tagad",
    backToToday: "Atpakaļ pie šodienas",
    ok: "Ok",
    clear: "Skaidrs",
    month: "Mēnesis",
    year: "Gads",
    timeSelect: "Izvēlieties laiku",
    dateSelect: "Izvēlieties datumu",
    monthSelect: "Izvēlieties mēnesi",
    yearSelect: "Izvēlieties gadu",
    decadeSelect: "Izvēlieties desmit gadus",
    yearFormat: "YYYY",
    dateFormat: "D.M.YYYY",
    dayFormat: "D",
    dateTimeFormat: "D.M.YYYY HH:mm:ss",
    monthBeforeYear: true,
    previousMonth: "Iepriekšējais mēnesis (PageUp)",
    nextMonth: "Nākammēnes (PageDown)",
    previousYear: "Pagājušais gads (Control + left)",
    nextYear: "Nākamgad (Control + right)",
    previousDecade: "Pēdējā desmitgadē",
    nextDecade: "Nākamā desmitgade",
    previousCentury: "Pagājušajā gadsimtā",
    nextCentury: "Nākamajā gadsimtā",
  },
  timePickerLocale: {
    placeholder: "Izvēlieties laiku",
    rangePlaceholder: ["Sākuma laiks", "Beigu laiks"],
  },
};

export default locale;
