import React from "react";
import { Button, Popover } from "antd";

import { useTranslation } from "react-i18next";

const SkIdConfirm = (props) => {
  const { t } = useTranslation();

  const content = t(
    props.isSmart ? "smartIdConfirmText" : "mobileIdConfirmText"
  );

  return (
    <>
      <div className="smartIdConfirm">
        <label>{t("controlCode")}</label>
        <span>{props.code}</span>
        <p>{t(props.isSmart ? "smartIdConfirmText" : "mobileIdConfirmText")}</p>
        <Popover content={content} title={t("needHelp")}>
          <Button type="link"> {t("needHelp")}</Button>
        </Popover>
        <Button onClick={props.canceled} type="primary">
          {t("cancel")}
        </Button>
      </div>
    </>
  );
};

export default SkIdConfirm;
