import React from "react";
import { Table } from "antd";

const CustomTable = (props) => (
  <Table
    locale={props.tableLocale}
    className={props.className}
    columns={props.columns}
    rowKey={(record) => record.id}
    dataSource={props.dataSource}
    onChange={props.onChange}
    loading={props.loading}
    pagination={props.pagination}
    onExpand={props.onExpand}
    expandedRowRender={props.expandedRowRender}
    expandedRowKeys={props.expandedRowKeys}
    onRow={props.onRow}
    rowClassName={props.rowClassName}
    expandable={props.expandable}
    expandRowByClick
  />
);

export default CustomTable;
