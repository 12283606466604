import React, { useState, useEffect } from "react";

import Row from "components/shared/Row";
import ContractHeaderContent from "./contractHeaderContent";

const ContractFilterSingle = (props) => {
  const [selectedContract, setSelectedContract] = useState(props.selectedContract);

  useEffect(() => {
    setSelectedContract(props.selectedContract);
  }, [props.selectedContract]);

  const contractList = props.contractList;

  const contractSelected = (id) => {
    props.contractSelected(id);

    // Get selected contract.
    let selectedContractObject = props.contractList.find((obj) => obj.id === id);

    setSelectedContract(selectedContractObject);
  };

  // This if is a temp. solution, more info here: https://avalanchelabs.atlassian.net/browse/SSP-498
  if (typeof contractList !== "undefined" && typeof selectedContract !== "undefined") {
    return (
      <div className={`block contractFilterSingle ContractFilter ${contractList.length === 1 && "single-item"}`}>
        <div className={"contractsSelectMenu"}>
          {contractList.map((item, i) => (
            <Row
              key={item.id}
              className={selectedContract.id === item.id ? "selected" : "not-selected"}
              onClick={() => {
                contractSelected(item.id);
              }}
            >
              <ContractHeaderContent
                type={
                  props.type === "invoiceUpload" ||
                  props.type === "contracts" ||
                  props.type === "no-factoring" ||
                  props.type === "default" ||
                  props.type === "factoring-overview" ||
                  props.type === "loan"
                    ? props.type
                    : "no-factoring-dashboard"
                }
                key={item.id + i}
                item={item}
                productType={props.productType}
                productName={props.productName}
              />
            </Row>
          ))}
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default ContractFilterSingle;
