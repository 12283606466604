import React from "react";
import { useTranslation } from "react-i18next";
import ModalListSelect from "components/shared/ModalListSelect";

const NordigenDirectOptions = ({ onFinish }) => {
  const { t } = useTranslation();

  const options = [
    {
      id: "nordigen",
      img: "Nordigen",
      name: t("leasingFormConfirmationNordigen"),
      description: t("leasingFormConfirmationNordigenDescription"),
    },
    {
      id: "none",
      img: "None",
      name: t("leasingFormConfirmationNone"),
      description: t("leasingFormConfirmationNoneDescription"),
    },
  ];

  return (
    <ModalListSelect
      options={options}
      preselected={"nordigen"}
      onFinish={onFinish}
      headingText={t("leasingFormBusinessConfirmationSelectorHeading")}
      okText={t("leasingFormBusinessConfirmationSelectorOk")}
      />
  );
};

export default NordigenDirectOptions;
