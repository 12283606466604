import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import React from "react";
import { useTranslation } from "react-i18next";

const LoadingSpin = () => {
  const { t } = useTranslation();

  return (
    <div className="loading-spin__loading-screen">
      <Spin
        indicator={
          <LoadingOutlined
            className="loading-spin__loading-indicator"
            spin
          />
        }
      />
      <h2>{t("g4rDescriptionLoading")}</h2>
    </div>);
};

export default LoadingSpin;
