import React from "react";

import BankStatementFormController from "components/forms/bankStatements/BankStatementFormController";

const BankStatement = () => {

  return (
    <BankStatementFormController />
  );
};

export default BankStatement;
