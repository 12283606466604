import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import queryString from "query-string";

import DokobitSubmitter from "./submitters/DokobitSubmitter";

import { postApplicantData } from "services/formService";

const GuarantorConfirmation = ({ toggle, formStartCb, formContinueCb, formExitCb }) => {
  const location = useLocation();
  const { token: applicantToken } = queryString.parse(location.search);
  const { applicationToken } = useParams();

  useEffect(() => {
    formStartCb();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DokobitSubmitter
        toggle={toggle}
        formContinueCb={formContinueCb}
        formExitCb={formExitCb}
        submitCallFn={(data) => postApplicantData(applicationToken, applicantToken, data)}
      />
    </>
  );
};

export default GuarantorConfirmation;
