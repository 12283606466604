import React, { useContext } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import Table from "components/shared/Table";
import Button from "components/shared/Button";
import Tooltip from "components/shared/Tooltip";
import { useTranslation } from "react-i18next";
import { DownloadOutlined } from "@ant-design/icons";
import { getContractFile } from "services/contractService";
import { AuthContext } from "App";
import { contractDocumentsType } from "types/contract";
import apiWrapper from "services/apiWrapper";

const ContractSignTable = ({ data, openDokobit, loading }) => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(AuthContext);

  const structuredData = data.map((item) => ({
    id: item.id,
    contractId: item.contractId,
    name: item.fileName,
    status: item.status,
    signers: item.signers,
  }));

  const renderButton = (_text, record) => {
    const isSigner = record.signers.some((signer) => signer.email === state.user?.email);
    const isSigned = record.status === "SIGNED";

    return (
      <div className="contract-sign-table__actions">
        {!isSigned && (
          <Tooltip
            overlayClassName="contract-sign-table__tooltip"
            title={!isSigner ? t("contractTableSignButtonTooltip") : ""}
          >
            {/* https://github.com/react-component/tooltip/issues/18#issuecomment-140078802 */}
            <span>
              <Button
                type="primary"
                size="small"
                onClick={() => openDokobit(record)}
                disabled={isSigned ? true : !isSigner}
                className={cx({ "contract-sign-table__disabled-button": !isSigner })}
              >
                {t("contractTableSignButton")}
              </Button>
            </span>
          </Tooltip>
        )}
        <DownloadOutlined className="contract-sign-table__download-icon" onClick={() => downloadDocument(record.id)} />
      </div>
    );
  };

  const columns = [
    {
      title: t("contractSignTableName"),
      dataIndex: "name",
      key: "name",
      width: "10.5rem",
    },
    {
      title: t("contractSignTableStatus"),
      dataIndex: "status",
      key: "status",
      width: "7rem",
      align: "center",
      render: (text) => {
        switch (text) {
          case "UNSIGNED":
            return t("contractSignTableStatusUnsigned");
          case "SIGNED":
            return t("contractSignTableStatusSigned");
          default:
            return text;
        }
      },
    },
    {
      title: t("contractSignTableSigners"),
      dataIndex: "signers",
      key: "signers",
      width: "8rem",
      render: (list) => (
        <ul className="contract-sign-table__list">
          {list.map((item) => (
            <li key={item.email}>{`${item.firstName} ${item.lastName}`}</li>
          ))}
        </ul>
      ),
    },
    {
      title: t("contractSignTableAction"),
      key: "action",
      width: "7rem",
      align: "center",
      render: renderButton,
    },
  ];

  const downloadDocument = async (id) => {
    apiWrapper(await getContractFile(id), dispatch);
  };

  return (
    <Table
      className="contract-sign-table"
      rowKey="name"
      columns={columns}
      dataSource={structuredData}
      pagination={false}
      loading={loading}
    />
  );
};

ContractSignTable.propTypes = {
  data: contractDocumentsType.isRequired,
  openDokobit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ContractSignTable;
