import React, { useEffect, useState } from "react";
import { Input, Select, Form, Row, Col, Checkbox } from "antd";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import Modal from "components/shared/Modal";
import FloatFormItem from "../shared/floatFormItem";
import PhoneInput from "../shared/formItems/PhoneInput";

import { AuthContext } from "App";
import { factoringPermissions, loanPermissions, permissionTypes } from "utils/statusUtils";
import { defaultLabelText, requiredField } from "utils/formUtils";
import { productType, hasProductTypeInMainParty, hasProductPermission } from "utils/partiesUtils";
import { getAccountUserPermissionsTitle } from "utils/translationIdUtils";

const { Option } = Select;

const countries = [
  {
    code: "EE",
    dial_code: "+372",
    latitude: 59,
    longitude: 26,
    name: "Estonia",
  },
  {
    code: "LV",
    dial_code: "+371",
    latitude: 57,
    longitude: 25,
    name: "Latvia",
  },
  {
    code: "LT",
    dial_code: "+370",
    latitude: 56,
    longitude: 24,
    name: "Lithuania",
  },
];

const NewUser = (props) => {
  const { t } = useTranslation();
  const { state } = React.useContext(AuthContext);
  const [form] = Form.useForm();

  const [modalVisible, setModalVisible] = useState(false);
  const [countryLabelText, setCountryLabelText] = useState(defaultLabelText);
  const [phoneNoText, setPhoneNoText] = useState(defaultLabelText);
  const [phoneText, setPhoneText] = useState(defaultLabelText);
  const [personalCodeText, setPersonalCodeText] = useState(defaultLabelText);
  const [languageText, setLanguageText] = useState(defaultLabelText);

  const language = {
    EN: {
      code: "EN",
      title: t("languageEN"),
    },
    LT: {
      code: "LT",
      title: t("languageLT"),
    },
    LV: {
      code: "LV",
      title: t("languageLV"),
    },
    EE: {
      code: "EE",
      title: t("languageEE"),
    },
  };

  const errorText = (errorCode) => {
    let message = "";

    switch (errorCode) {
      case "SME52":
        message = t("accountNewUserLastNameInvalidMessage");
        break;
      case "SME51":
        message = t("accountNewUserFirstNameInvalidMessage");
        break;
      case "SME50":
        message = t("accountNewUserEmailInvalidMessage");
        break;
      case "SME55":
        message = t("accountNewUserPhoneInvalidMessage");
        break;
      case "SME56":
        message = t("accountNewUserMobileIdPhoneInvalidMessage");
        break;
      case "SME57":
        message = t("accountNewUserPersonalCodeInvalidMessage");
        break;
      default:
        message = t("error");
    }

    return message;
  };

  const errorName = (errorCode) => {
    let name = "";

    switch (errorCode) {
      case "SME52":
        name = "surname";
        break;
      case "SME55":
        name = "phoneNumber";
        break;
      case "SME56":
        name = "mobilePhoneNo";
        break;
      case "SME57":
        name = "personalCode";
        break;
      default:
        name = "name";
    }

    return name;
  };

  useEffect(() => {
    setModalVisible(props.modalVisible);
  }, [props.modalVisible]);

  const validateMessages = {
    required: `${t("email")} ${t("isRequired")}`,
    types: {
      email: t("isNotValidEmail"),
    },
  };

  const resetLabels = () => {
    setCountryLabelText(defaultLabelText);
    setPhoneText(defaultLabelText);
    setLanguageText(defaultLabelText);
  };

  const resetBackendValidations = (form) => {
    const names = ["surname", "phoneNumber", "mobilePhoneNo", "personalCode", "name"];

    for (const productTypeValue of Object.values(productType)) {
      if (!hasProductTypeInMainParty(state.parties, productTypeValue)) {
        continue;
      }
      names.push(`"permissions_${productType}"`);
    }

    names.map((name) => {
      form.setFields([
        {
          name: name,
          errors: [],
        },
      ]);

      return null;
    });
  };

  const getAvailablePermissions = (_productType) => {
    const availablePermissions = Object.entries(
      _productType?.toUpperCase() === productType.Loan.toUpperCase() ? loanPermissions : factoringPermissions
    ).map((i) => ({
      label: t(i[1]),
      value: i[0],
    }));

    return availablePermissions;
  };

  const showProductError = (translationId) => {
    for (const productTypeValue of Object.values(productType)) {
      if (!hasProductTypeInMainParty(state.parties, productTypeValue)) {
        continue;
      }
      form.setFields([
        {
          name: `permissions_${productTypeValue}`,
          errors: [t(translationId)],
        },
      ]);
    }
  };

  return (
    <>
      <Modal
        width="48.125rem"
        className="newUser customForm contractFunction modal personal-information-action blue"
        title={t("accountAddNewUserTitle")}
        visible={modalVisible}
        okText={t("accountSendInvitation")}
        cancelText={t("cancel")}
        cancelButtonProps={{
          className: "button button--default button--large",
        }}
        okButtonProps={{
          className: "button button--primary button--large",
        }}
        onCancel={() => {
          form.resetFields();
          resetLabels();
          setModalVisible(false);
          props.onModalClose(false);
        }}
        onOk={() => {
          resetBackendValidations(form);

          form
            .validateFields()
            .then(async (values) => {
              const productList = [];
              for (const productTypeValue of Object.values(productType)) {
                if (!hasProductTypeInMainParty(state.parties, productTypeValue)) {
                  continue;
                }

                let currentPermissions = values[`permissions_${productTypeValue}`];
                if (currentPermissions && currentPermissions.length) {
                  productList.push({
                    product_type: productTypeValue.toUpperCase(),
                    permissions: currentPermissions.map((i) => i.toUpperCase()),
                  });
                }
              }

              if (!productList.length) {
                showProductError("accountNewUserChoosePermissionsError");
                return;
              }

              const response = await props.postNewUser({
                first_name: values.name,
                last_name: values.surname,
                email: values.email,
                phone_number:
                  values.phoneNumber && values.phoneNumber !== "" ? values.phoneCode + values.phoneNumber : null,
                language: values.language,
                country: values.country,
                personal_code: values.personalCode && values.personalCode !== "" ? values.personalCode : null,
                mobile_id_phone_number:
                  values.mobilePhoneNo && values.mobilePhoneNo !== ""
                    ? values.mobileIdPhoneCode + values.mobilePhoneNo
                    : null,
                product_list: productList,
              });

              if (response.code) {
                if (response.code === "SME49") {
                  showProductError("accountNewUserProductAssignedAlreadyMessage");
                } else {
                  form.setFields([
                    {
                      name: errorName(response.code),
                      errors: [errorText(response.code)],
                    },
                  ]);
                }

                return;
              }

              props.onModalSave(values);
              form.resetFields();
              resetLabels();
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Row>
          <Col span={24}>
            <Form
              form={form}
              layout="vertical"
              name="form_in_modal"
              initialValues={{
                modifier: "public",
                phoneCode: "+370",
                mobileIdPhoneCode: "+370",
              }}
              validateMessages={validateMessages}
            >
              <FloatFormItem
                type={Input}
                label={t("name")}
                name="name"
                rules={[
                  {
                    required: true,
                    message: `${t("name")} ${t("isRequired")}`,
                  },
                ]}
                style={{ display: "inline-block", width: "calc(50% - 12px)" }}
              />
              <FloatFormItem
                type={Input}
                label={t("surname")}
                name="surname"
                rules={[
                  {
                    required: true,
                    message: `${t("surname")} ${t("isRequired")}`,
                  },
                ]}
                style={{ display: "inline-block", width: "calc(52%)" }}
              />

              <FloatFormItem
                type={Input}
                label={t("email")}
                name="email"
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: `${t("email")} ${t("isRequired")}`,
                  },
                ]}
              />
              <PhoneInput
                countriesPhoneCodes={props.countriesWithPhoneCodes}
                phoneText={phoneNoText}
                setPhoneText={setPhoneNoText}
                placeholder={t("phoneNumber")}
                form={form}
                required={false}
              />
              <Form.Item
                label={languageText}
                name="language"
                rules={[
                  {
                    required: true,
                    message: `${t("language")} ${t("isRequired")}`,
                  },
                ]}
              >
                <Select
                  onChange={(value) => setLanguageText(value ? t("language") : defaultLabelText)}
                  placeholder={requiredField(t("language"))}
                >
                  {Object.values(language).map((item) => (
                    <Option key={item.code} value={item.code}>
                      {item.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              {Object.values(productType)
                .filter(
                  (productType) =>
                    hasProductTypeInMainParty(state.parties, productType) &&
                    hasProductPermission(state.parties, productType, permissionTypes.ManageUsers)
                )
                .map((productType) => (
                  <div key={`permission-${productType}`}>
                    <h3>{t(getAccountUserPermissionsTitle(productType))}</h3>
                    <Form.Item name={`permissions_${productType}`} label="">
                      <Checkbox.Group>
                        {getAvailablePermissions(productType)?.map((i) => (
                          <Row key={`row-permission-${productType}-${i.value}`}>
                            <Col span={24}>
                              <Checkbox value={i.value} disabled={i.disabled} style={{ lineHeight: "32px" }}>
                                {i.label}
                              </Checkbox>
                            </Col>
                          </Row>
                        ))}
                      </Checkbox.Group>
                    </Form.Item>
                  </div>
                ))}

              <p>{t("addNewUserInfoText")}</p>

              <h3> {t("accountNewUserSmartId")} </h3>

              <Form.Item
                label={countryLabelText}
                name="country"
                rules={[
                  {
                    message: t("country"),
                  },
                ]}
                style={{ display: "inline-block", width: "calc(40% - 12px)" }}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  style={{
                    width: "100%",
                  }}
                  onChange={(value) => setCountryLabelText(value ? t("country") : defaultLabelText)}
                  placeholder={t("country")}
                >
                  {props.countries.length &&
                    props.countries
                      .filter((i) => countries.map((j) => j.code).includes(i.codeAlpha2))
                      .map((item, i) => (
                        <Option key={i} value={item.codeAlpha2}>
                          {item.translations[i18next.language.toUpperCase()]}
                        </Option>
                      ))}
                </Select>
              </Form.Item>

              <Form.Item
                label={personalCodeText}
                name="personalCode"
                style={{ display: "inline-block", width: "calc(62%)" }}
              >
                <Input
                  onChange={(event) => {
                    const { value } = event.target;

                    form.setFieldsValue({
                      personalCode: value,
                    });

                    setPersonalCodeText(event.target.value ? t("personalCode") : defaultLabelText);
                  }}
                  placeholder={t("personalCode")}
                />
              </Form.Item>

              <h3>{t("accountNewUserMobileId")}</h3>
              <PhoneInput
                countriesPhoneCodes={props.countriesWithPhoneCodes}
                phoneText={phoneText}
                setPhoneText={setPhoneText}
                placeholder={t("phoneNo")}
                form={form}
                name="mobilePhoneNo"
                prefixName="mobileIdPhoneCode"
              />
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default NewUser;
