import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input, Select, Form, Modal } from "antd";

import { Row, Col } from "antd";

const { Option } = Select;

const EditCompany = (props) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const [editCompanyModalVisible, setEditCompanyModalVisible] = useState(false);

  useEffect(() => {
    setEditCompanyModalVisible(props.editCompanyModalVisible);
  }, [props.editCompanyModalVisible]);

  const prefixSelector = (
    <Form.Item name="phoneCode" noStyle>
      <Select
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        style={{
          width: 90,
        }}
      >
        {props.countries.length &&
          props.countries.map((item, i) => (
            <Option key={i} value={item.dial_code}>
              {item.dial_code}
            </Option>
          ))}
      </Select>
    </Form.Item>
  );

  const [labelText, setLabelText] = useState({
    street: "\xa0",
    houseNo: "\xa0",
    city: "\xa0",
    postalCode: "\xa0",
    phoneNumber: "\xa0",
  });

  const updateLabel = (name, value) => {
    setLabelText({ ...labelText, [name]: value ? t(name) : "\xa0" });
  };

  return (
    <>
      <Modal
        forceRender
        width="48.125rem"
        className="editCompany"
        title={t("editCompanyDetails")}
        visible={editCompanyModalVisible}
        okText={t("save")}
        cancelText={t("cancel")}
        onCancel={() => {
          setEditCompanyModalVisible(false);
          props.onEditCompanyClose(false);
        }}
        cancelButtonProps={{
          className: "button button--default button--large",
        }}
        okButtonProps={{
          className: "button button--primary button--large",
        }}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              // form.resetFields();
              props.onEditCompanySave(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Row>
          <Col span={22}>
            <label>{t("companyName")}</label>
            <p>{props.company?.name || "-"}</p>
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <label>{t("country")}</label>
            <p>{props.company?.country || "-"}</p>
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <label>{t("companyCode")}</label>
            <p>{props.company?.registrationCode || "-"}</p>
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <label>{t("vatCode")}</label>
            <p>{props.company?.vatNumber || "-"}</p>
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <h3>{t("companyAddress")}</h3>
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <Form
              form={form}
              layout="vertical"
              name="form_in_modal"
              initialValues={{
                modifier: "public",
                phoneCode: "+370",
                street: props.company?.address ? props.company.address?.addressLine1 : null,
                houseNo: props.company?.address ? props.company.address?.addressLine1 : null,
                city: props.company?.address ? props.company.address.city : null,
                postalCode: props.company?.address ? props.company.address?.postalCode : null,
                phoneNumber: props.company?.phoneNumber,
              }}
            >
              <Form.Item
                name="street"
                label={labelText["street"]}
                style={{ display: "inline-block", width: "calc(70% - 12px)" }}
              >
                <Input onChange={(event) => updateLabel("street", event.target.value)} placeholder={t("street")} />
              </Form.Item>
              <Form.Item
                name="houseNo"
                label={labelText["houseNo"]}
                style={{ display: "inline-block", width: "calc(30% - 12px)" }}
              >
                <Input onChange={(event) => updateLabel("houseNo", event.target.value)} placeholder={t("houseNo")} />
              </Form.Item>
              <Form.Item
                name="city"
                label={labelText["city"]}
                style={{ display: "inline-block", width: "calc(70% - 12px)" }}
              >
                <Input placeholder={t("city")} onChange={(event) => updateLabel("city", event.target.value)} />
              </Form.Item>
              <Form.Item
                name="postalCode"
                label={labelText["postalCode"]}
                style={{ display: "inline-block", width: "calc(30% - 12px)" }}
              >
                <Input
                  placeholder={t("postalCode")}
                  onChange={(event) => updateLabel("postalCode", event.target.value)}
                />
              </Form.Item>
              <Form.Item name="phoneNumber" label={labelText["phoneNumber"]}>
                <Input
                  onChange={(event) => updateLabel("phoneNumber", event.target.value)}
                  addonBefore={prefixSelector}
                  style={{
                    width: "100%",
                  }}
                  placeholder={t("phoneNumber")}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default EditCompany;
