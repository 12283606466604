import React, { useEffect, useState } from "react";

import NordigenDirectOptions from "./options/NordigenDirectOptions";
import NordigenSubmitter from "./submitters/NordigenSubmitter";

import { getFromLocalStorage } from "services/formService";

const BusinessConfirmation = ({ toggle, formStartCb, formContinueCb, formExitCb, submitFn }) => {
  const [nordigenConfirmationToggle, setNordigenConfirmationToggle] = useState(false);
  const [toggleWrap, setToggleWrap] = useState(toggle);

  const onConfirmationSelect = (confirmationProcessorId) => {
    if (!confirmationProcessorId) {
      // cancelled
      return formContinueCb();
    }
    setToggleWrap(false);

    if (confirmationProcessorId === "none") {
      (async () => {
        const response = await submitFn({
          ...getFromLocalStorage(),
          signing: {
            type: "NONE",
          },
        });
        formExitCb(!!response.ok);
      })();
    } else if (confirmationProcessorId === "nordigen") {
      setNordigenConfirmationToggle(true);
    }
  };

  useEffect(() => {
    setToggleWrap(toggle);
  }, [toggle]);

  return (
    <>
      {toggleWrap && <NordigenDirectOptions onFinish={onConfirmationSelect} />}

      <NordigenSubmitter
        toggle={nordigenConfirmationToggle}
        onToggleChange={setNordigenConfirmationToggle}
        formStartCb={(error) => {
          setNordigenConfirmationToggle(false);
          formStartCb(error);
        }}
        formContinueCb={(error) => {
          setNordigenConfirmationToggle(false);
          formContinueCb(error);
        }}
        formExitCb={(state) => {
          setNordigenConfirmationToggle(false);
          formExitCb(state);
        }}
        submitCallFn={submitFn}
      />
    </>
  );
};

export default BusinessConfirmation;
