const locale = {
  lang: {
    placeholder: "Valige kuupäev",
    yearPlaceholder: "Valige aasta",
    quarterPlaceholder: "Valige kvartal",
    monthPlaceholder: "Valige kuu",
    weekPlaceholder: "Valige nädal",
    rangePlaceholder: ["Algus kuupäev", "Lõppkuupäev"],
    rangeYearPlaceholder: ["Algusaasta", "Aasta lõpp"],
    rangeMonthPlaceholder: ["Alguskuu", "Kuu lõpp"],
    rangeWeekPlaceholder: ["Alusta nädalat", "Nädala lõpp"],
    locale: "et_EE",
    today: "Täna",
    now: "Praegu",
    backToToday: "Tagasi tänase juurde",
    ok: "Ok",
    clear: "Tühista",
    month: "Kuu",
    year: "Aasta",
    timeSelect: "Vali aeg",
    dateSelect: "Vali kuupäev",
    monthSelect: "Vali kuu",
    yearSelect: "Vali aasta",
    decadeSelect: "Vali dekaad",
    yearFormat: "YYYY",
    dateFormat: "D.M.YYYY",
    dayFormat: "D",
    dateTimeFormat: "D.M.YYYY HH:mm:ss",
    monthBeforeYear: true,
    previousMonth: "Eelmine kuu (PageUp)",
    nextMonth: "Järgmine kuu (PageDown)",
    previousYear: "Eelmine aasta (Control + left)",
    nextYear: "Järgmine aasta (Control + right)",
    previousDecade: "Eelmine dekaad",
    nextDecade: "Järgmine dekaad",
    previousCentury: "Eelmine sajand",
    nextCentury: "Järgmine sajand",
  },
  timePickerLocale: {
    placeholder: "Vali aeg",
    rangePlaceholder: ["Algusaeg", "Lõpuaeg"],
  },
};

export default locale;
