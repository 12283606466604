import React from "react";
import { Row, Col } from "antd";

import MiniDonutPie from "components/contract/miniDonutPie";
import BasicContractInfo from "./basicContractInfo";
import ContractFiles from "./contractFiles";
import ContractBuyers from "./contractBuyers";

const ContractDetails = (props) => {
  return (
    <div>
      <Row>
        <Col span={17} className="section section--mt-1">
          <BasicContractInfo
            contract={props.contract}
            contractList={props.contractList}
            productFid={props.productFid}
            prolong={props.prolong}
            increaseLimit={props.increaseLimit}
            increaseLimitSubmit={props.increaseLimitSubmit}
            prolongSubmit={props.prolongSubmit}
            getIncreaseLimitFileUploadData={props.getIncreaseLimitFileUploadData}
            getProlongFileUploadData={props.getProlongFileUploadData}
            deleteIncreaseLimitFile={props.deleteIncreaseLimitFile}
            deleteProlongFile={props.deleteProlongFile}
            uploadedFiles={props.uploadedFiles}
            filesChanged={props.filesChanged}
            showIncreaseLimitView={props.showIncreaseLimitView}
            contractFunctionEnabled={props.contractFunctionEnabled}
          />
        </Col>
        <Col span={7} className="section section--ml-1 section--mt-1">
          <MiniDonutPie type="loan" showPlate={true} data={props.donutData} />
          <ContractFiles
            contract={props.contract}
            contractFiles={props.contractFiles}
            downloadContractFile={props.downloadContractFile}
          />
        </Col>
      </Row>

      <Row className="section section--mt-1">
        <Col span={24}>
          <ContractBuyers
            contract={props.contract}
            addBuyer={props.addBuyer}
            addNewBuyerSubmit={props.addNewBuyerSubmit}
            newBuyerEnabled={props.newBuyerEnabled}
            showBuyerDetails={props.showBuyerDetails}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ContractDetails;
