import React, { useState } from "react";
import { Spin } from "antd";
import { useTranslation } from "react-i18next";

import SmsStart from "./smsStart";
import SmsConfirm from "./smsConfirm";

import { authenticateUserBySms, reloginUserBySms, getCorrectLanguage } from "./../../services/userService";
import { productType, getActiveParty, getActiveProduct } from "utils/partiesUtils";

const Sms = (props) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [data, setData] = useState("");

  const sendSms = async (values) => {
    return await authenticateUserBySms({
      ...data,
      sms_code: values?.codeFromSms,
    });
  };

  const resendSms = async (values) => {
    return await reloginUserBySms({
      ...data,
      sms_code: values?.codeFromSms,
    });
  };

  const valuesSubmitted = (values) => {
    setData(values);
    setStep(2);
  };

  const loginUser = async (result) => {
    setLoading(true);

    const userInfo = await props.getUser(result.token);

    result["user"] = {
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      accountId: userInfo.id,
      language: getCorrectLanguage(result.token, userInfo.language),
      country: userInfo.country,
      phone: userInfo.phoneNumber,
      email: userInfo.email,
      termsConditionsAccepted: userInfo.termsConditionsAccepted,
    };

    const userParties = await props.getParties(result.token);
    result["parties"] = userParties;

    const activeParty = getActiveParty(userParties);
    const activeProduct = getActiveProduct(activeParty);

    // party and product fid are equals, only because it's BO workaround
    const boParty = await props.getBoParty(
      activeProduct.type === productType.Factoring.toUpperCase() ? activeProduct?.fid : activeParty?.fid,
      result.token
    );

    result["assignedManager"] = boParty?.assignedManager;

    props.loginSuccess(result);
  };

  return (
    <>
      <Spin spinning={loading} tip={t("loading") + "..."}>
        {step === 1 && (
          <SmsStart
            login={props.login}
            loginBySms={props.loginBySms}
            getUser={props.getUser}
            getParties={props.getParties}
            getBoParty={props.getBoParty}
            loginSuccess={props.loginSuccess}
            valuesSubmitted={valuesSubmitted}
            logoutReason={props.logoutReason}
          />
        )}
        {step !== 1 && <SmsConfirm sendSms={sendSms} resendSms={resendSms} login={loginUser} />}
      </Spin>
    </>
  );
};

export default Sms;
