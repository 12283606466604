import React from "react";
import { useTranslation } from "react-i18next";

import Row from "components/shared/Row";
import Col from "components/shared/Col";
import TextArea from "components/shared/form/TextArea";

const AdditionalInformationBlock = ({ readOnly }) => {
  const { t } = useTranslation();

  return (
    <>
      <h3 className="leasing-request-form__heading">{t("g4rFormAdditionalInfo")}</h3>
      <Row className="hidden-ant-form-item-label">
        <Col span={24}>
          <TextArea
            label={t("g4rFormAdditionalInfo")}
            name={["applicant", "additional_information"]}
            rules={[
              {
                max: 250,
                message: t("g4rFormFieldValueTooLong"),
              },
            ]}
            disabled={readOnly}
          />
        </Col>
      </Row>
    </>
  );
};

export default AdditionalInformationBlock;
