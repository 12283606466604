import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Collapse, Row, Col } from "antd";

import Button from "../shared/Button";
import IconArrow from "../../assets/icons/icon_arrow.svg";
import cx from "classnames";

import ContractHeaderContent from "components/contractSelector/contractHeaderContent";
import BuyerContact from "./upload/verification/buyerContact";
import DocumentsList from "./upload/verification/documentsList";

import { moneyFormatter } from "../../utils/moneyUtils";

const { Panel } = Collapse;
const InvoiceVerification = (props) => {
  const { t } = useTranslation();

  const [buyersInvoices, setBuyersInvoices] = useState([{}]);
  const [buyersContacts, setBuyersContacts] = useState([]);
  const [expandedItems, setExpandedItems] = useState(["0"]);

  useEffect(() => {
    setBuyersInvoices(props.buyersInvoices);
  }, [props.buyersInvoices]);

  useEffect(() => {
    setBuyersContacts(props.buyersContacts);
  }, [props.buyersContacts]);

  const buyersChange = (value) => {
    setExpandedItems(value);
  };

  return (
    <>
      <div className="block selectedContract">
        <Row className={"onlySelectedContract"}>
          <ContractHeaderContent
            //spanSizes={[4, 3, 3, 3, 3, 5]}
            item={props.contract}
            type="no-factoring"
          />
        </Row>
      </div>
      <div className="invoice-verification">
        <Collapse
          expandIconPosition="right"
          defaultActiveKey="0"
          expandIcon={({ isActive }) => (
            <Button
              icon={
                <img
                  src={IconArrow}
                  shape="round"
                  alt="arrow"
                  className={cx("invoice-verification__arrow-icon", {
                    "invoice-verification__arrow-icon--active": isActive,
                  })}
                />
              }
            />
          )}
          onChange={buyersChange}
        >
          {buyersInvoices.map((buyer, i) => (
            <Panel
              // forceRender={true}
              header={
                expandedItems.includes(i.toString()) ? (
                  <h3 className="invoice-verification__category-heading">{buyer.buyerName}</h3>
                ) : (
                  <Row>
                    <Col span="7">{buyer.buyerName}</Col>
                    <Col span="4">
                      {t("invoiceUploadNumberOfInvoices")}

                      <span className="invoice-verification__category__invoice-number__header">
                        {buyer.totalInvoices}
                      </span>
                    </Col>
                    <Col offset="4" span="7" className="invoice-verification__category__total">
                      <span className="invoice-verification__category__total__header">{t("total")}</span>
                      <span className="invoice-verification__category__total__text">
                        {moneyFormatter(buyer.totalInvoiceAmount, buyer.currency)}
                      </span>
                    </Col>
                  </Row>
                )
              }
              key={i}
              className={`invoice-verification__category ${
                props.showEmptyContactsError && props.buyersWithEmptyContacts.some((i) => i === buyer.buyerCode)
                  ? "error"
                  : ""
              }`}
            >
              <div className="invoice-verification__category__description">
                {t("uploadInvoiceBuyerContactDescription")}
              </div>
              <BuyerContact
                contract={props.contract}
                buyerId={buyersContacts.find((i) => i.code === buyer.buyerCode)?.id}
                buyerName={buyersContacts.find((i) => i.code === buyer.buyerCode)?.name}
                buyerCode={buyer.buyerCode}
                contacts={props.buyersContacts.find((i) => i.code === buyer.buyerCode)?.contacts}
                reloadContacts={props.reloadContacts}
                updateBuyerSelectedContact={props.updateBuyerSelectedContact}
                showError={
                  props.showEmptyContactsError && props.buyersWithEmptyContacts.some((i) => i === buyer.buyerCode)
                }
              />
              <DocumentsList
                invoiceDocumentsList={buyer.invoiceUploadList}
                total={buyer.totalInvoiceAmount}
                currency={buyer.currency}
              />
            </Panel>
          ))}
        </Collapse>
      </div>
    </>
  );
};

export default InvoiceVerification;
