import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const FilterButton = (props) => {
  const [active, setActive] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setActive(props.active);
  }, [props.active]);

  const onChange = (e) => {
    props.onChange({ [props.name]: !active });
    setActive(!active);
  };

  return (
    <>
      <label onChange={onChange} className={"active-" + active}>
        <input type="checkbox" />
        {t(props.name)}
        <span className="filterDataValue"> {props.value} </span>
      </label>
    </>
  );
};

export default FilterButton;
