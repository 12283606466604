import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Switch, Row, Col } from "antd";

const EmailNotificationSettings = props => {
  const { t } = useTranslation();

  const [enableNotificationsChecked, setEnableNotificationsChecked] = useState(
    props.emailNotificationSettings.enableNotifications
  );

  const [invoiceFinancedChecked, setInvoiceFinancedChecked] = useState(
    props.emailNotificationSettings.invoiceFinanced
  );

  const [billPaymentTimeEndsChecked, setBillPaymentTimeEndsChecked] = useState(
    props.emailNotificationSettings.billPaymentTimeEnds
  );

  const [invoiceOverdueChecked, setInvoiceOverdueChecked] = useState(
    props.emailNotificationSettings.invoiceOverdue
  );

  const [invoiceVerifiedChecked, setInvoiceVerifiedChecked] = useState(
    props.emailNotificationSettings.invoiceVerified
  );

  const [lateFeeCalculatedChecked, setLateFeeCalculatedChecked] = useState(
    props.emailNotificationSettings.lateFeeCalculated
  );

  const [invoiceRejectedChecked, setInvoiceRejectedChecked] = useState(
    props.emailNotificationSettings.invoiceRejected
  );

  const onEnableNotificationsChange = checked => {
    setEnableNotificationsChecked(checked);
    props.emailNotificationSettingsChanged({
      emailNotificationSettings: checked
    });
  };

  const onInvoiceFinancedChange = checked => {
    setInvoiceFinancedChecked(checked);
    props.emailNotificationSettingsChanged({ invoiceFinanced: checked });
  };

  const onBillPaymentTimeEndsChange = checked => {
    setBillPaymentTimeEndsChecked(checked);
    props.emailNotificationSettingsChanged({ billPaymentTimeEnds: checked });
  };

  const onInvoiceOverdueChange = checked => {
    setInvoiceOverdueChecked(checked);
    props.emailNotificationSettingsChanged({ invoiceOverdue: checked });
  };
  const onInvoiceVerifiedChange = checked => {
    setInvoiceVerifiedChecked(checked);
    props.emailNotificationSettingsChanged({ invoiceVerified: checked });
  };

  const onLateFeeCalculatedChange = checked => {
    setLateFeeCalculatedChecked(checked);
    props.emailNotificationSettingsChanged({ lateFeeCalculated: checked });
  };

  const onInvoiceRejectedChange = checked => {
    setInvoiceRejectedChecked(checked);
    props.emailNotificationSettingsChanged({ invoiceRejected: checked });
  };
  return (
    <>
      <Row>
        <Col span={5}>
          <label>{t("enableNotifications")}</label>
          <p>
            <Switch
              checked={enableNotificationsChecked}
              onChange={onEnableNotificationsChange}
            />
          </p>
        </Col>
        <Col span={5}>
          <label>{t("invoiceFinanced")}</label>
          <p>
            <Switch
              checked={invoiceFinancedChecked}
              onChange={onInvoiceFinancedChange}
            />
          </p>
        </Col>
        <Col span={5}>
          <label>{t("billPaymentTimeEnds")}</label>
          <p>
            <Switch
              checked={billPaymentTimeEndsChecked}
              onChange={onBillPaymentTimeEndsChange}
            />
          </p>
        </Col>
        <Col span={5}>
          <label>{t("invoiceOverdue")}</label>
          <p>
            <Switch
              checked={invoiceOverdueChecked}
              onChange={onInvoiceOverdueChange}
            />
          </p>
        </Col>
      </Row>
      <Row>
        <Col span={5}>
          <label>{t("invoiceVerified")}</label>
          <p>
            <Switch
              checked={invoiceVerifiedChecked}
              onChange={onInvoiceVerifiedChange}
            />
          </p>
        </Col>
        <Col span={5}>
          <label>{t("lateFeeCalculated")}</label>
          <p>
            <Switch
              checked={lateFeeCalculatedChecked}
              onChange={onLateFeeCalculatedChange}
            />
          </p>
        </Col>
        <Col span={5}>
          <label>{t("invoiceRejected")}</label>
          <p>
            <Switch
              checked={invoiceRejectedChecked}
              onChange={onInvoiceRejectedChange}
            />
          </p>
        </Col>
      </Row>
    </>
  );
};

export default EmailNotificationSettings;
