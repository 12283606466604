import React from "react";
import Image from "./Image";
import icons from "utils/icons";

export const IconSmall = ({ type }) => {
  return <Image src={icons[type]} className={"icon-small"} alt={`${type} icon`} />;
};
export const IconBig = ({ type }) => {
  return <Image src={icons[type]} className={"icon-big"} alt={`${type} icon`} />;
};
export const IconResponsive = ({ type }) => {
  return <Image src={icons[type]} className={"icon-responsive"} alt={`${type} icon`} />;
};
