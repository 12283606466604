import React, { useState } from "react";
import { Alert, Button, Row, Col, notification } from "antd";
import { useTranslation } from "react-i18next";
import { CheckCircleOutlined } from "@ant-design/icons";

import CheckPassword from "./personalInfoActions/checkPassword";
import EditEmail from "./personalInfoActions/editEmail";
import EditPhone from "./personalInfoActions/editPhone";

const PersonalInformation = (props) => {
  const alertTimeout = 20;
  const { t } = useTranslation();

  const [type, setType] = useState(null);

  const [checkPasswordModalVisible, setCheckPasswordModalVisible] = useState(
    false
  );

  const [editEmailModalVisible, setEditEmailModalVisible] = useState(false);

  const [editPhoneModalVisible, setEditPhoneModalVisible] = useState(false);

  const onEditEmailModalClose = () => {
    setEditEmailModalVisible(false);
  };

  const onEditEmailModalSave = () => {
    notification["success"]({
      message: t("success"),
      description: t("accountChangeEmailStatusMessage"),
      duration: alertTimeout,
    });

    setEditEmailModalVisible(false);

    props.logoutUser();
  };

  const onEditPhoneModalClose = () => {
    setEditPhoneModalVisible(false);
  };

  const onEditPhoneModalSave = () => {
    notification["success"]({
      message: t("success"),
      description: t("accountChangePhoneStatusMessage"),
      duration: alertTimeout,
    });

    setEditPhoneModalVisible(false);
  };

  const onPasswordModalClose = () => {
    setCheckPasswordModalVisible(false);
  };

  const onPasswordModalSave = () => {
    setCheckPasswordModalVisible(false);

    if (type === "email") {
      setEditEmailModalVisible(true);
    } else {
      setEditPhoneModalVisible(true);
    }
  };

  const onEditEmailClick = () => {
    setType("email");
    setCheckPasswordModalVisible(true);
  };

  const onEditPhoneClick = () => {
    setType("phone");
    setCheckPasswordModalVisible(true);
  };

  return (
    <>
      <CheckPassword
        type={type}
        modalVisible={checkPasswordModalVisible}
        onModalClose={onPasswordModalClose}
        onModalSave={onPasswordModalSave}
        authorizeUser={props.authorizeUser}
        changePassword={props.changePassword}
        email={props?.person?.email}
      />
      <EditEmail
        modalVisible={editEmailModalVisible}
        onModalClose={onEditEmailModalClose}
        onModalSave={onEditEmailModalSave}
        currentValue={props?.person?.email}
        changeEmail={props.changeEmail}
      />
      <EditPhone
        modalVisible={editPhoneModalVisible}
        onModalClose={onEditPhoneModalClose}
        onModalSave={onEditPhoneModalSave}
        currentValue={props?.person?.user_metadata?.phone_number}
        countriesWithPhoneCodes={props.countriesWithPhoneCodes}
        changePhone={props.changePhone}
      />
      <Row className={"PersonalInformation"}>
        <Col span={12}>
          <Row>
            <Col span={24}>
              <Row className={"infoRow name"}>
                <Col span={24}>
                  <label>{t("name")}</label>
                  <p>{props.person?.user_metadata?.first_name || "—"}</p>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <label>{t("surname")}</label>
                  <p>{props.person?.user_metadata?.last_name|| "—"}</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row className={"infoRow"}>
            <Col span={20}>
              <label>{t("email")}</label>
              <p>{props.person?.email|| "—"}</p>
              <Alert
                message={
                  props.person.email_verified
                    ? t("accountEmailVerifiedStatus")
                    : t("accountEmailPendingStatus")
                }
                type={props.person.email_verified ? "success" : "warning"}
                icon={<CheckCircleOutlined />}
                showIcon={props.person.email_verified}
              />
            </Col>
            <Col span={4}>
              <label>&nbsp;</label>
              <p>
                <Button
                  type="link"
                  onClick={onEditEmailClick}
                  className="edit"
                ></Button>
              </p>
            </Col>
          </Row>
          <Row>
            <Col span={20}>
              <label>{t("phone")}</label>
              <p>{props.person?.user_metadata?.phone_number|| "—"}</p>
              {props.person.phoneConfirmed && (
                <Alert
                  message={t("confirmed")}
                  type="success"
                  icon={<CheckCircleOutlined />}
                  showIcon
                />
              )}
            </Col>
            <Col span={2}>
              <label>&nbsp;</label>
              <p>
                <Button
                  type="link"
                  onClick={onEditPhoneClick}
                  className="edit"
                ></Button>
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default PersonalInformation;
