import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "antd";
import { UserContext } from "contexts/userContext";

import Row from "components/shared/Row";
import Col from "components/shared/Col";
import Spin from "components/shared/Spin";
import Modal from "components/shared/Modal";
import PhoneInput from "components/shared/formItems/PhoneInput";
import Input from "components/shared/form/Input";

import { editContact } from "services/buyerService";

const EditContact = ({ companyName, modalVisible, data, buyerDetails, contract, closeModal }) => {
  const { countriesPhoneCodes } = useContext(UserContext);
  const { name, surname, email, phoneNumber, jobTitle } = data;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const errorText = (errorCode) => {
    let message = "";

    switch (errorCode) {
      case "SME50":
        message = t("accountNewUserEmailInvalidMessage");
        break;
      case "SME51":
        message = t("accountNewUserFirstNameInvalidMessage");
        break;
      case "SME52":
        message = t("accountNewUserLastNameInvalidMessage");
        break;
      case "SME55":
        message = t("accountNewUserPhoneInvalidMessage");
        break;
      case "SME62":
        message = t("newContactInvalidJobTitle");
        break;
      default:
        message = t("error");
    }

    return message;
  };

  const errorName = (errorCode) => {
    let name = "";

    switch (errorCode) {
      case "SME50":
        name = "email";
        break;
      case "SME51":
        name = "name";
        break;
      case "SME52":
        name = "surname";
        break;
      case "SME55":
        name = "number";
        break;
      case "SME57":
        name = "personalCode";
        break;
      default:
        name = "name";
    }

    return name;
  };

  useEffect(() => {
    form.resetFields();
  });
  return (
    <Modal
      forceRender
      width="48.125rem"
      className="edit-contact modal grey"
      title={t("editContactTitle")}
      visible={modalVisible}
      okText={t("submit")}
      cancelText={t("cancel")}
      cancelButtonProps={{
        className: "button button--default button--large",
      }}
      okButtonProps={{
        className: "button button--primary button--large",
      }}
      onCancel={() => {
        closeModal();
      }}
      onOk={() => {
        form
          .validateFields()
          .then(async ({ name, surname, phoneCode, number, jobTitle }) => {
            setLoading(true);
            const result = await editContact({
              contractId: contract.id,
              thirdPartytId: buyerDetails.party.id,
              email,
              data: {
                jobTitle: jobTitle ? jobTitle : "",
                name,
                phoneNumber: number ? phoneCode + number : phoneNumber ? phoneNumber : "",
                surname,
              },
            });
            setLoading(false);
            if (result.code) {
              form.setFields([
                {
                  name: errorName(result.code),
                  errors: [errorText(result.code)],
                },
              ]);
            } else {
              closeModal("success");
              form.resetFields();
            }
          })
          .catch((e) => {
            console.log("Validate faild: ", e);
          });
      }}
    >
      <Spin spinning={loading} tip={t("loading") + "..."}>
        <Row className="first-row-padding">
          <Col>
            <label>{t("editContactCompanyName")}</label>
            <p>{companyName}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <label>{t("editContactCurrentFullName")}</label>
            <p>{`${name} ${surname}`}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <label>{t("editContactCurrentEmail")}</label>
            <p>{email}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <label>{t("editContactCurrentPhone")}</label>
            <p>{phoneNumber ? phoneNumber : "-"}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <label>{t("editContactCurrentJobTitle")}</label>
            <p>{jobTitle ? jobTitle : "-"}</p>
          </Col>
        </Row>
        <Row>
          <Col span="24">
            <Form
              layout="vertical"
              className="data-form"
              placeholder={t("accountNewPhone")}
              form={form}
              initialValues={{
                phoneCode: "+370",
                name: name,
                surname: surname,
                jobTitle: jobTitle ? jobTitle : "",
              }}
            >
              <Row>
                <Col span="11">
                  <Input
                    label={t("editContactName")}
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: t("editContactNameValidation"),
                      },
                    ]}
                  />
                </Col>
                <Col span="2" />
                <Col span="11">
                  <Input
                    label={t("editContactSurname")}
                    name="surname"
                    rules={[
                      {
                        required: true,
                        message: t("editContactSurnameValidation"),
                      },
                    ]}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Input label={t("editContactJobTitle")} name="jobTitle" />
                </Col>
                <Col span="2" />
                <Col span="11" className="row-without-labels">
                  <PhoneInput name="number" form={form} countriesPhoneCodes={countriesPhoneCodes} />
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Spin>
    </Modal>
  );
};

export default EditContact;
