import React, { useEffect, useState, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";

import Spin from "components/shared/Spin";
import Row from "components/shared/Row";
import Col from "components/shared/Col";
import Button from "components/shared/Button";

import Transactions from "./transactions";
import Fees from "./fees";

import { AuthContext } from "App";

import {
  getInvoice,
  getInvoicePayouts,
  getInvoicePaymentReceipts,
  getInvoiceInterestFees,
  getInvoiceLateFees,
  getInvoiceCommissionFees,
  getInvoiceVerification,
} from "services/invoiceService";
import { getContract } from "services/contractService";

import { getPdfFile } from "services/fileService";

import { moneyFormatter } from "utils/moneyUtils";
import { percentageNumberFormatter } from "utils/numberUtils";
import { reformatUnderscore } from "utils/helpers";
import apiWrapper from "services/apiWrapper";

const InvoiceDetail = (props) => {
  const { t } = useTranslation();
  const [invoice, setInvoice] = useState(null);
  const [invoicePayouts, setInvoicePayouts] = useState(null);
  const [invoicePayoutsTotals, setInvoicePayoutsTotals] = useState(null);
  const [invoicePaymentReceipts, setInvoicePaymentReceipts] = useState(null);
  const [invoicePaymentReceiptsTotals, setInvoicePaymentReceiptsTotals] = useState(null);
  const [invoiceInterestFees, setInvoiceInterestFees] = useState(null);
  const [invoiceInterestFeesTotals, setInvoiceInterestFeesTotals] = useState(null);

  const [residualInvoiceAmount, setResidualInvoiceAmount] = useState(null);

  const [invoiceLateFees, setInvoiceLateFees] = useState(null);
  const [invoiceLateFeesTotals, setInvoiceLateFeesTotals] = useState(null);
  const [invoiceCommissionFees, setInvoiceCommissionFees] = useState(null);
  const [invoiceListPath, setInvoiceListPath] = useState(null);
  const [nextInvoicePath, setNextInvoicePath] = useState(null);
  const [previousInvoicePath, setPreviousInvoicePath] = useState(null);
  const [contract, setContract] = useState(null);
  const { state: authState, dispatch } = useContext(AuthContext);

  const [backButtonIsActive, setBackButtonIsActive] = useState(true);
  const [nextButtonIsActive, setNextButtonIsActive] = useState(true);
  const [verificationData, setVerificationData] = useState(null);
  const [loading, setLoading] = useState(false);

  const productFid = authState.activeProductFid;

  const formatInvoiceUrl = (id, selectedContracts, filteredInfo) => {
    return `/factoring/invoice/${id}/contractId=${selectedContracts}&${new URLSearchParams(filteredInfo).toString()}`;
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const currentProductFid = usePrevious(productFid);

  useEffect(() => {
    if (currentProductFid && productFid !== currentProductFid) {
      props.history.push("/factoring/invoices");
    }

    async function fetchData() {
      const { id } = props.match.params;

      let selectedContracts = {};
      let filter = {};

      if (props.match.params.filter) {
        const searchParams = new URLSearchParams(props.match.params.filter);

        selectedContracts = searchParams.get("contractId").split(",");

        for (const key of searchParams.keys()) {
          if (searchParams.get(key) !== "undefined" && key !== "contractId" && key !== "page" && key !== "size") {
            filter[key] = searchParams.get(key);
          }
        }
      }

      if (props.location.state) {
        setInvoiceListPath(props.location?.state?.pathname);
      }

      const invoiceResponse = apiWrapper(await getInvoice(id, selectedContracts, filter, 1, 1), dispatch);
      setInvoice(invoiceResponse ? invoiceResponse.invoice : null);

      const invoicePayoutsResponse = apiWrapper(await getInvoicePayouts(id), dispatch);
      setInvoicePayouts(invoicePayoutsResponse ? invoicePayoutsResponse.invoicePayouts : []);

      setInvoicePayoutsTotals(invoicePayoutsResponse ? invoicePayoutsResponse.totalInvoicePaymentAmount : {});

      const invoicePaymentReceiptsResponse = apiWrapper(await getInvoicePaymentReceipts(id), dispatch);
      setInvoicePaymentReceipts(
        invoicePaymentReceiptsResponse ? invoicePaymentReceiptsResponse.invoicePaymentReceipts : []
      );

      setResidualInvoiceAmount(invoicePaymentReceiptsResponse?.residualInvoiceAmount);

      setInvoicePaymentReceiptsTotals(
        invoicePaymentReceiptsResponse ? invoicePaymentReceiptsResponse.totalInvoiceCollectedAmount : {}
      );

      const invoiceInterestFeesResponse = apiWrapper(await getInvoiceInterestFees(id), dispatch);
      setInvoiceInterestFees(invoiceInterestFeesResponse ? invoiceInterestFeesResponse.invoiceInterestFeesActs : []);

      setInvoiceInterestFeesTotals(invoiceInterestFeesResponse ? invoiceInterestFeesResponse.totalInvoiceAmount : {});

      const invoiceLateFeesResponse = apiWrapper(await getInvoiceLateFees(id), dispatch);
      setInvoiceLateFees(invoiceLateFeesResponse ? invoiceLateFeesResponse.invoiceLateFeesActs : []);

      setInvoiceLateFeesTotals(invoiceLateFeesResponse ? invoiceLateFeesResponse.totalInvoiceAmount : {});

      const invoiceCommissionFeesResponse = apiWrapper(await getInvoiceCommissionFees(id), dispatch);
      setInvoiceCommissionFees(
        invoiceCommissionFeesResponse ? invoiceCommissionFeesResponse.invoiceCommissionFees : []
      );

      if (invoiceResponse?.invoice) {
        const contractResponse = apiWrapper(await getContract(invoiceResponse.invoice.contractId), dispatch);

        setContract(contractResponse);
      }

      setNextButtonIsActive(!!invoiceResponse?.nextInvoiceId);

      if (invoiceResponse?.nextInvoiceId) {
        setNextInvoicePath(formatInvoiceUrl(invoiceResponse.nextInvoiceId, selectedContracts, filter));
      }

      setBackButtonIsActive(!!invoiceResponse?.previousInvoiceId);

      if (invoiceResponse?.previousInvoiceId) {
        setPreviousInvoicePath(formatInvoiceUrl(invoiceResponse.previousInvoiceId, selectedContracts, filter));
      }
    }
    if (productFid) {
      fetchData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params, props.location.state, props.history, productFid]);

  useEffect(() => {
    const listener = (e) => {
      switch (e.code) {
        case "ArrowLeft":
          if (backButtonIsActive && previousInvoicePath) {
            props.history.push(previousInvoicePath);
          }
          break;

        case "ArrowRight":
          if (nextButtonIsActive && nextInvoicePath) {
            props.history.push(nextInvoicePath);
          }
          break;

        default:
          break;
      }
    };

    document.addEventListener("keydown", listener);

    return () => {
      document.removeEventListener("keydown", listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.history, nextInvoicePath, previousInvoicePath, backButtonIsActive, nextButtonIsActive]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const response = await getInvoiceVerification(
        invoice.id,
        invoice.contractId,
        invoice.thirdPartyId,
        invoice.buyerVerificationId
      );

      setVerificationData(response);
      setLoading(false);
    };

    if (invoice && invoice.buyerVerificationStatus !== "NOT_APPLICABLE") {
      fetchData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice]);

  const goBackToInvoiceList = () => {
    const pathParts = props.location && props.location.pathname.split("/");

    props.history.push(
      invoiceListPath || "/factoring/invoices/" + (props.location ? pathParts[pathParts.length - 1] : "")
    );
  };

  const goToNextInvoice = () => {
    props.history.push(nextInvoicePath);
  };

  const goToPreviousInvoice = () => {
    props.history.push(previousInvoicePath);
  };

  const isToday = (dateString) => {
    const today = new Date();
    const date = new Date(dateString);
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  const dateFormatter = (date) => {
    return date ? date.split("T")[0] : date;
  };

  const invoiceStatuses = [
    { value: "SUBMITTED", text: t("submitted") },
    { value: "REJECTED", text: t("rejected") },
    { value: "FINANCED", text: t("financed") },
    { value: "OVERDUE", text: t("overdue") },
    { value: "PARTIALLY_PAID", text: t("partiallyPaid") },
    { value: "PAID", text: t("paid") },
    { value: "CLOSED", text: t("closed") },
    { value: "TRANSFERRED", text: t("transfered") },
  ];

  const getProperStatus = (needle) => {
    const result = invoiceStatuses.filter((obj) => {
      return obj.value === needle;
    });

    if (result.length !== 0) {
      return result[0].text;
    }

    return needle;
  };

  const downloadPdfFile = async (fileId) => {
    apiWrapper(await getPdfFile(fileId), dispatch);
  };

  const setVerificationTitle = (status) => {
    switch (status) {
      case "WAITING":
        return <Col className="verificationTitle waiting">{t("invoiceVerificationWaiting")}</Col>;
      case "NOT_APPLICABLE":
        return <Col className="verificationTitle notApplicable">{t("invoiceVerificationNotApplicable")}</Col>;
      case "ACCEPTED":
        return <Col className="verificationTitle accepted">{t("invoiceVerificationAccepted")}</Col>;
      case "REJECTED":
        return <Col className="verificationTitle rejected">{t("invoiceVerificationRejected")}</Col>;
      default:
        return <Col className="verificationTitle notApplicable">{t("invoiceVerificationNotApplicable")}</Col>;
    }
  };

  return (
    <div className={"contractDetailView"}>
      {invoice && (
        <div
          className={
            "block invoiceStatusCarpet invoiceStatus-" +
            invoice.invoiceStatus.replace(/\s/g, "").replace("_", "").toLowerCase()
          }
        >
          <Row>
            <Col>
              <Row className={"contractTitleAndInfo"}>
                <Col>
                  <Row className={"invoiceBrief"}>
                    <Col span={8}>
                      <h2>
                        <span className={"invoiceNo"}>{t("invoiceDetailsInvoiceNo")}</span>
                        {invoice.invoiceNumber}
                      </h2>
                    </Col>
                    <Col span={16}>
                      <span
                        className={
                          "invoiceStatus invoiceStatus-" + invoice.invoiceStatus.replace(/\s/g, "").toLowerCase()
                        }
                      >
                        {getProperStatus(invoice.invoiceStatus)}
                      </span>
                    </Col>
                  </Row>
                  {contract && (
                    <Row className={"invoiceBrief"}>
                      <Col>
                        <label>{t("invoiceDetailsContract")}</label>
                        <p>
                          <span className="type-name">
                            {contract.factoringProduct
                              ? t(`factoringProduct${contract.factoringProduct.toLowerCase()}`)
                              : ""}
                          </span>
                          <span> {t("factoring")} </span>
                        </p>
                      </Col>
                      <Col>
                        <label>{t("invoiceDetailsNo")}</label>
                        <p>{contract.contractNumber}</p>
                      </Col>
                      <Col className={"limit-col"}>
                        <label>{t("invoiceDetailsLimit")}</label>
                        <p>{moneyFormatter(contract.factoringCreditLimit, contract.financingCurrencyCode)}</p>
                      </Col>
                      <Col>
                        <label>{t("invoiceDetailsUsed")}</label>
                        <p>{moneyFormatter(contract.usedFactoringCreditLineAmount, contract.financingCurrencyCode)}</p>
                      </Col>
                      <Col>
                        <label>{t("invoiceDetailsAvailable")}</label>
                        <p>
                          {moneyFormatter(contract.availableFactoringCreditLineAmount, contract.financingCurrencyCode)}
                        </p>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
              <Row className={"contractData"}>
                <Col span={18}>
                  <Row>
                    <Col span={12}>
                      <label>
                        {t(contract?.factoringProduct !== "REVERSE" ? "invoiceDetailsBuyer" : "invoiceDetailsSupplier")}
                      </label>
                      <p>
                        {invoice.thirdPartyName
                          ? invoice.thirdPartyName
                          : invoice.invoiceType
                          ? t(reformatUnderscore(invoice.invoiceType))
                          : ""}
                      </p>
                      <label>{t("invoiceDetailsCompanyCode")}</label>
                      <p>{invoice.thirdPartyRegistrationCode}</p>
                    </Col>
                    <Col span={12} className={"penaltyTwo"}>
                      <label>{t("invoiceDetailsAmount")} </label>
                      <p>{moneyFormatter(invoice.invoiceAmount.amount, invoice.invoiceAmount.currency)}</p>
                      <label>{t("invoiceDetailsTransferredClaim")}</label>
                      <p>
                        {moneyFormatter(invoice.invoiceAmountAssigned.amount, invoice.invoiceAmountAssigned.currency)}
                        <label>{`/ ${percentageNumberFormatter(invoice.invoiceAmountAssignedRate * 100)}%`}</label>
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <label>{t("invoiceDetailsIssueDate")}</label>
                      <p>
                        {dateFormatter(invoice.invoiceDate)}
                        {isToday(invoice.invoiceDate) && `(${t("today")})`}
                      </p>
                      <label>{t("invoiceDetailsSubmitted")}</label>
                      <p>{dateFormatter(invoice.submittedAt)}</p>
                    </Col>
                    <Col span={6} className={"penaltyOne"}>
                      <label>{t("invoiceDetailsDueDate")}</label>
                      <p>{dateFormatter(invoice.invoiceDueDate)}</p>
                      <label>{t("invoiceDetailsApproved")}</label>
                      <p>{dateFormatter(invoice.approvalDate)}</p>
                    </Col>
                    <Col span={6} className={"penaltyTwo"}>
                      <label>{t("invoiceDetailsAdvance")}</label>
                      <p>
                        {moneyFormatter(invoice.advanceAmountTotal.amount, invoice.advanceAmountTotal.currency)}
                        <label>
                          {invoice.advanceRate && `/ ${percentageNumberFormatter(invoice.advanceRate * 100)}%`}
                        </label>
                      </p>
                      <label> {t("invoiceDetailsRemainder")} </label>
                      <p>
                        {moneyFormatter(invoice.reserveAmountTotal.amount, invoice.reserveAmountTotal.currency)}
                        <label>
                          {invoice.reserveRate && `/ ${percentageNumberFormatter(invoice.reserveRate * 100)}%`}
                        </label>
                      </p>
                    </Col>
                    <Col span={6} className={"penaltyThree"}>
                      <label>{t("invoiceDetailsResidualAdvance")} </label>
                      <p>
                        {moneyFormatter(invoice.advanceAmountUnfunded.amount, invoice.advanceAmountUnfunded.currency)}
                      </p>
                      <label>{t("invoiceDetailsResidualRemainder")} </label>
                      <p>
                        {moneyFormatter(
                          invoice.reserveAmountOutstanding.amount,
                          invoice.reserveAmountOutstanding.currency
                        )}
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col span={6} className="verificationStatus">
                  <Spin spinning={loading} tip={t("loading") + "..."}>
                    <Row>{setVerificationTitle(invoice.buyerVerificationStatus)}</Row>
                    <Row>
                      <Col>
                        <label>{t("invoiceVerificationContact")}</label>
                        <p>{verificationData?.name ? `${verificationData.name} ${verificationData.surname}` : "-"}</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label>{t("invoiceVerificationEmail")}</label>
                        <p className={verificationData?.email ? "invoiceVerificationEmail" : ""}>
                          {verificationData?.email || "-"}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label>{t("invoiceVerificationPhone")}</label>
                        <p>{verificationData?.phoneNumber || "-"}</p>
                      </Col>
                    </Row>
                  </Spin>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={18}>
              <Transactions
                invoiceAmountUnfunded={invoice.invoiceAmountUnfunded}
                residualInvoiceAmount={residualInvoiceAmount}
                invoicePayouts={invoicePayouts}
                invoicePayoutsTotals={invoicePayoutsTotals}
                invoicePaymentReceipts={invoicePaymentReceipts}
                invoicePaymentReceiptsTotals={invoicePaymentReceiptsTotals}
                downloadPdfFile={downloadPdfFile}
              />

              <Fees
                invoiceInterestFees={invoiceInterestFees}
                invoiceInterestFeesTotals={invoiceInterestFeesTotals}
                invoiceLateFees={invoiceLateFees}
                invoiceLateFeesTotals={invoiceLateFeesTotals}
                invoiceCommissionFees={invoiceCommissionFees}
                downloadPdfFile={downloadPdfFile}
              />
            </Col>
          </Row>
        </div>
      )}
      <div className={"buttons"}>
        <div>
          <Button className={"goBackToInvoiceList"} type="default" onClick={goBackToInvoiceList}>
            {t("back")}
          </Button>
        </div>
        <div>
          <Button
            className={"goBackToInvoiceList"}
            type="default"
            onClick={goToPreviousInvoice}
            disabled={!backButtonIsActive}
          >
            {t("previous")}
          </Button>
          <Button
            className={"goBackToInvoiceList"}
            type="primary"
            onClick={goToNextInvoice}
            disabled={!nextButtonIsActive}
          >
            {t("next")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default InvoiceDetail;
