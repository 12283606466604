import settings from "../config/config";

export const fileValidationErrorTypes = {
  Size: "size",
  Format: "format",
};

export const fileValidationStatus = (file) => {
  if (!fileSizeIsValid(file.size)) {
    return fileValidationErrorTypes.Size;
  }

  if (!fileFormatIsValid(file.type)) {
    return fileValidationErrorTypes.Format;
  }

  return null;
};

export const excelValidationStatus = (file) => {
  if (!fileSizeIsValid(file.size)) {
    return fileValidationErrorTypes.Size;
  }

  console.log("file format", file.type);

  return null;
};

export const processFileValidationMessages = (info, t) => {
  return info.fileList.map((file) => {
    if (file.status !== "error") return file;

    if (file.response.code === "SME11") {
      file.response = t("SME11");
      file.hasError = true;
    }

    if (file.response.code === "SME32" || file.response.code === "SME33") {
      file.response = t("SME32");
      file.hasError = true;
    }

    return file;
  });
};

export const processInvalidFileValidationText = (file, status, errorText) => {
  file.validationText = errorText;

  return file;
};

export const validateFiles = (fileList) => {
  return fileList.map((file) => {
    if (file.validationText) {
      file.status = "error";
      file.response = file.validationText;
    }

    return file;
  });
};


//TODO: remove this method and use isFileSizeValid
export const fileSizeIsValid = (fileSize) => {
  return fileSize / 1024 / 1024 <= settings.file.size;
};

//TODO: remove this method and use hasValidFilesTypes
const fileFormatIsValid = (fileFormat) => {
  for (const type of settings.file.types) {
    if (fileFormat === type) {
      return true;
    }
  }

  return false;
};


const getSizeMBToBytes = (megaBytes) => {
  return megaBytes * Math.pow(1024, 2);
};

export const isFileSizeValid = (filesSizeInBytes, maxFilesSizeInMB) => {
  return filesSizeInBytes <= getSizeMBToBytes(maxFilesSizeInMB);
};

export const hasValidFileTypes = (fileNameArray, extensionsRegex) => {
  const regex = new RegExp(extensionsRegex);
  return fileNameArray.every((fileName) => regex.test(fileName));
};
