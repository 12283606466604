import React, { useEffect, useState, Fragment } from "react";

import { useTranslation } from "react-i18next";

import { Row, Col, Modal } from "antd";

const fileErrorCodes = ["SME9", "SME11", "SME29", "SME30", "SME31"];

const AutomaticUploadStatus = (props) => {
  const { t } = useTranslation();

  const [uploadStatusModalVisible, setUploadStatusModalVisible] = useState(false);

  const [erroredInvoices, setErroredInvoices] = useState([]);

  useEffect(() => {
    setErroredInvoices(props.erroredInvoices);
    setUploadStatusModalVisible(props.uploadStatusModalVisible);
  }, [props.uploadStatusModalVisible, props.erroredInvoices]);

  useEffect(() => {
    if (!erroredInvoices.length) return;

    erroredInvoices.map((invoice) => {
      if (fileErrorCodes.indexOf(invoice.code) > -1) {
        invoice.errorType = "file";
        return invoice;
      }

      invoice.errorType = "line";

      return null;
    });
  }, [erroredInvoices]);

  const onContinueClick = () => {
    props.onUploadStatusClose();
    setUploadStatusModalVisible(false);
  };

  const renderLineErrorOcr = (invoice, i) => (
    <Row key={`line-error-${i}`}>
      <Col className="message" span={21}>
        <Row>
          <Col span={21}>
            <strong>
              {t("file")}: {invoice.originalFilename}{" "}
            </strong>
            ({t("totalInvoices")}: {invoice.totalInvoices})
          </Col>
        </Row>
        <p>
          {t("correctInvoices")}: {invoice.totalValidInvoices}
        </p>
        <p className="incorrectInvoices">{t("incorrectInvoices")}</p>
        <Row>
          <Col span={7}>{t("invoiceNo")}</Col>
          <Col span={15}>{t("errors")}</Col>
        </Row>

        {invoice.invoiceErrors &&
          invoice.invoiceErrors.length &&
          invoice.invoiceErrors.map((invoiceError, j) => (
            <Row key={j}>
              <Col span={7}>{invoiceError.invoiceNumber}</Col>
              <Col span={15} className="error">
                {invoiceError.lineErrors.map((lineError, k) => (
                  <Row key={k}>
                    <Col span={22}>{lineError.errorCode && t(lineError.errorCode)}</Col>
                  </Row>
                ))}
              </Col>
            </Row>
          ))}
      </Col>
    </Row>
  );

  const renderLineErrorTemplate = (invoice, i) => (
    <Row key={`line-error-${i}`}>
      <Col className="message" span={21}>
        <Row>
          <Col span={21}>
            <strong>
              {t("file")}: {invoice.originalFilename}{" "}
            </strong>
            ({t("totalInvoices")}: {invoice.totalInvoices})
          </Col>
        </Row>
        <p>
          {t("correctInvoices")}: {invoice.totalValidInvoices}
        </p>
        <p className="incorrectInvoices">{t("incorrectInvoices")}</p>
        <Row>
          <Col span={2}>{t("line")}</Col>
          <Col span={5} className="automaticUploadStatus-invoiceNumber">
            {t("invoiceNo")}
          </Col>
          <Col span={15}>{t("errors")}</Col>
        </Row>

        {invoice.invoiceErrors &&
          invoice.invoiceErrors.length &&
          invoice.invoiceErrors.map((invoiceError, j) => (
            <Row key={j}>
              <Col span={2}>{invoiceError.line}</Col>
              <Col span={5} className="automaticUploadStatus-invoiceNumber">
                {invoiceError.invoiceNumber}
              </Col>
              <Col span={15} className="error">
                {invoiceError.lineErrors.map((lineError, k) => (
                  <Row key={k}>
                    <Col span={22}>{lineError.errorCode && t(lineError.errorCode)}</Col>
                  </Row>
                ))}
              </Col>
            </Row>
          ))}
      </Col>
    </Row>
  );

  const renderFileError = (invoice, i) => (
    <Row key={`file-error-${i}`}>
      <Col className="message" span={21}>
        {invoice.code !== "SME9" && (
          <Row>
            <Col span={21}>
              <p>
                <strong>{`${t("file")}: ${invoice.extMessage}`}</strong>
              </p>
            </Col>
          </Row>
        )}

        <Row>
          {erroredInvoices[0].code === "SME9" || erroredInvoices[0].code === "SME11" ? (
            <p>{t("templateSizeTooLarge")}</p>
          ) : (
            <Fragment>
              <p>{t("UnrecognizedTemplate")}</p>
              <p>{t("UseTemplate")}</p>
            </Fragment>
          )}
        </Row>
      </Col>
    </Row>
  );

  const renderBadRequest = (invoice, i) => (
    <Row key={`line-error-${i}`}>
      <Col className="message" span={21}>
        <Row>
          <Col span={21}>
            <strong>
              {t("file")}: {invoice.extMessage}
            </strong>
          </Col>
        </Row>

        <Row>{invoice.code && t(invoice.code)}</Row>
      </Col>
    </Row>
  );

  const renderInternalServerError = (invoice, i) => (
    <Row key={`line-error-${i}`}>
      <Col className="message" span={21}>
        <Row>
          <Col span={21}>
            <strong>
              {t("file")}: {invoice.message}
            </strong>
          </Col>
        </Row>

        <Row>{invoice.code && t(invoice.code)}</Row>
      </Col>
    </Row>
  );

  const renderError = () => (
    <Fragment>
      {erroredInvoices.map((invoice, i) => {
        if (invoice.status === "BAD_REQUEST") {
          return renderBadRequest(invoice, i);
        }
        if (invoice.status === "INTERNAL_SERVER_ERROR" || invoice.status === "SERVICE_UNAVAILABLE") {
          return renderInternalServerError(invoice, i);
        }
        if (invoice.errorType === "file") {
          return renderFileError(invoice, i);
        }

        const isTemplateFile =
          invoice.invoiceErrors.filter((invoiceError) => invoiceError.line !== undefined).length > 0;
        if (isTemplateFile) {
          return renderLineErrorTemplate(invoice, i);
        }

        return renderLineErrorOcr(invoice, i);
      })}
    </Fragment>
  );

  return (
    <Fragment>
      <Modal
        forceRender
        className="uploadStatus automaticUploadStatus"
        title={t("templateParsingErrors")}
        visible={uploadStatusModalVisible}
        okText={t("continue")}
        maskClosable={false}
        onOk={onContinueClick}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{
          className: "button button--primary button--large",
        }}
      >
        {erroredInvoices && !!erroredInvoices.length && renderError()}
      </Modal>
    </Fragment>
  );
};

export default AutomaticUploadStatus;
