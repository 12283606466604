import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import cx from "classnames";

import { Form, Input } from "antd";

import { requiredField, defaultLabelText as emptyLabelText, stripStringToDecimal } from "utils/formUtils";

const CustomInput = ({
  type,
  name,
  rules,
  label = " ",
  value,
  onChange = () => undefined,
  triggerBlurAsChange = false,
  className,
  normalize,
  disabled = false,
  ...rest
}) => {
  const inputRef = useRef();

  const isRequired = !!rules?.filter((i) => !!i.required).length;
  const hasDecimalMask = type === "decimal";
  const defaultLabelText = isRequired ? requiredField(label) : label;
  const [labelText, setLabelText] = useState();
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    //catch initial value on render
    setCurrentValue(inputRef.current.props.value);
    onChange(inputRef.current.props.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    // intercept value from parent
    setCurrentValue(value);
  }, [value]);

  useEffect(() => {
    setLabelText(currentValue ? defaultLabelText : emptyLabelText);
  }, [currentValue, defaultLabelText]);

  const onChangeWrapper = (value_) => {
    if (triggerBlurAsChange) {
      // normalize value onBlur, as we do not respond to onChange here
      value_ = normalizeWraper(value_);
    }
    setCurrentValue(value_); // intercept value
    onChange && onChange(value_); // propagate up
  };

  const normalizeWraper = (...args) => {
    if (hasDecimalMask) {
      return (normalize && normalize(stripStringToDecimal(args[0]), ...args.slice(1))) || stripStringToDecimal(args[0]);
    }
    return (normalize && normalize(...args)) || args[0];
  };

  return (
    <>
      <Form.Item
        label={labelText}
        name={name}
        rules={rules}
        className={cx(className, {
          "input--active": undefined !== currentValue && !disabled,
        })}
        normalize={!triggerBlurAsChange ? normalizeWraper : undefined}
        validateTrigger="onBlur" // do not alert the user while typing
      >
        <Input
          className={cx("input", className)}
          placeholder={defaultLabelText}
          onChange={!triggerBlurAsChange ? (event) => onChangeWrapper(event.target.value) : undefined}
          onBlur={triggerBlurAsChange ? (event) => onChangeWrapper(event.target.value) : undefined}
          value={currentValue}
          ref={inputRef}
          disabled={disabled}
          {...rest}
        />
      </Form.Item>
    </>
  );
};

export default CustomInput;
