import React from "react";
import { Button } from "antd";

const ButtonLink = (props) => {
  return (
    <Button type="link" {...props} className={`link ${props.className ? props.className : ""}`}>
      {props.children}
    </Button>
  );
};

export default ButtonLink;
