import React from "react";
import PropTypes from "prop-types";

import publicLogo from "assets/logo/logo.svg";
import privateLogo from "assets/logo/logoPrivate.svg";
import g4rLogo from "assets/logo/g4r_logo.svg";

const logoList = {
  smeWhite: publicLogo,
  smeBlue: privateLogo,
  go4rent: g4rLogo,
};

const Logo = ({ type, responsive }) => {
  return <img src={logoList[type]} alt="logo" className={`logo ${responsive ? "logo--responsive" : ""}`} />;
};

Logo.propTypes = {
  type: PropTypes.oneOf(Object.keys(logoList)),
};

Logo.defaultProps = {
  type: "smeBlue",
};

export default Logo;
