import React from "react";

import { useTranslation } from "react-i18next";

import ButtonLink from "components/shared/ButtonLink";

import { moneyFormatter } from "utils/moneyUtils";
import { dateFormatter } from "utils/dateUtils";
import { reformatUnderscore } from "utils/helpers";

const ReportListItemDetails = ({ record, onGetPdfFile, expandedKeys, pdfText }) => {
  const { t } = useTranslation();
  return (
    <div
      className={`expanded-report-item ${
        expandedKeys && expandedKeys.includes(record.id) ? "isExpanded" : "notExpanded"
      }`}
    >
      <table>
        <colgroup>
          <col style={{ width: "125px" }} />
          <col style={{ width: "250px" }} />
          <col style={{ width: "175px" }} />
          <col style={{ width: "120px" }} />
          <col />
        </colgroup>
        <tbody>
          {record.reconciliationActs.map((act, i) => (
            <tr key={`${act.id}-${i.toString()}`}>
              <td>{dateFormatter(act.date)}</td>
              <td>{act.number}</td>
              <td>{act.type ? t(reformatUnderscore(act.type)) : t("interest")}</td>
              <td className="dataAlignAmount">{moneyFormatter(act.amount.amount, act.amount.currency)}</td>
              <td className="linkButton">
                <ButtonLink onClick={() => onGetPdfFile(act.id)}>{pdfText}</ButtonLink>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ReportListItemDetails;
