import React, { useState, useEffect } from "react";
import { Form, Input, Select } from "antd";
import Button from "../shared/Button";
import { useTranslation } from "react-i18next";

import { requiredField, defaultLabelText } from "./../../utils/formUtils";
import FloatFormItem from "../shared/floatFormItem";

const { Option } = Select;

const SkIdStart = (props) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const prefixSelector = (
    <Form.Item name="phoneCode" noStyle>
      <Select
        className="phoneCode"
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        {props.countries.length &&
          props.countries.map((item, i) => (
            <Option key={i} value={item.dial_code}>
              {item.dial_code}
            </Option>
          ))}
      </Select>
    </Form.Item>
  );

  const [labelText, setLabelText] = useState({
    country: defaultLabelText,
    personalCode: defaultLabelText,
    phoneNumber: defaultLabelText,
  });

  const updateLabel = (name, value) => {
    setLabelText({ ...labelText, [name]: value ? t(name) : defaultLabelText });
  };

  const onFinish = (values) => {
    props.valuesSubmitted(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (!props.error) return;

    form.setFields([
      {
        name: "username",
        errors: [t(props.isSmart ? "SkidError" : "mobileIdError")],
      },
    ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.error]);

  return (
    <>
      <Form
        name="smartid_login"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className="login-form"
        form={form}
        initialValues={{
          phoneCode: "+370",
        }}
      >
        <FloatFormItem
          type={Input}
          label={t("username")}
          name="username"
          rules={[{ required: true, message: t("username-empty-validation") }]}
        />
        {props.isSmart && (
          <>
            <Form.Item
              label={labelText["country"]}
              name="country"
              rules={[{ required: true, message: t("country-empty-validation") }]}
              style={{ display: "inline-block", width: "calc(40% - 12px)" }}
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                style={{
                  width: "100%",
                }}
                onChange={(value) => updateLabel("country", value)}
                placeholder={t("country")}
              >
                {props.countries.length &&
                  props.countries.map((item, i) => (
                    <Option key={i} value={item.code}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </>
        )}

        <Form.Item
          className="minimal-bmargin"
          name="personalCode"
          label={labelText["personalCode"]}
          rules={[{ required: true, message: t("personalCode-empty-validation") }]}
          style={props.isSmart ? { display: "inline-block", width: "calc(60% - 12px)" } : {}}
        >
          <Input
            onChange={(event) => updateLabel("personalCode", event.target.value)}
            placeholder={requiredField(t("personalCode"))}
          />
        </Form.Item>

        {!props.isSmart && (
          <Form.Item
            className="phoneNumber"
            name="phoneNumber"
            label={labelText["phoneNumber"]}
            rules={[{ required: true, message: t("phoneNumber-empty-validation") }]}
          >
            <Input
              onChange={(event) => updateLabel("phoneNumber", event.target.value)}
              addonBefore={prefixSelector}
              style={{
                width: "100%",
              }}
              placeholder={requiredField(t("phone"))}
            />
          </Form.Item>
        )}

        <Form.Item>
          <Button type="primary" size="large" htmlType="submit" className="login-form-button">
            {t("continue")}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default SkIdStart;
