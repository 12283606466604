import React from "react";
import { Empty } from "antd";

const CustomEmpty = ({ ...props }) => (
  <Empty
    {...props}
    className={`empty ${props.className}`}
    image={Empty.PRESENTED_IMAGE_SIMPLE}
    description={props.description}
  >
    {props.children}
  </Empty>
);

export default CustomEmpty;
