import React, { useState, useEffect } from "react";
import { Checkbox, Row, Col } from "antd";
import ContractHeaderContent from "components/contractSelector/contractHeaderContent";

const ContractFilter = (props) => {
  const [contractList, setContractList] = useState([]);

  const [selectedContracts, setSelectedContracts] = useState(
    props.contractList.filter((i) => props.currentContract.includes(i.id))
  );

  useEffect(() => {
    setSelectedContracts(props.contractList.filter((i) => props.currentContract.includes(i.id)));
  }, [props.currentContract, props.contractList]);

  useEffect(() => {
    const filteredList = props.contractList.filter((item) => item.status !== "READY_FOR_SIGNING");

    const sortedList = filteredList.sort((a, b) => (a.contractNumber > b.contractNumber ? 1 : -1));
    setContractList(sortedList);
  }, [props.contractList]);

  const onContractChange = (item) => {
    const contract = item.target.value;

    let contracts = !item.target.checked
      ? [...selectedContracts.filter((i) => i.id !== contract.id)]
      : [...selectedContracts, contract];

    if (contracts.length) {
      setSelectedContracts(contracts);
      props.contractsSelected(contracts.map((a) => a.id));
    }
  };

  const contractRowClicked = (item) => {
    onContractChange({
      target: {
        value: item,
        checked: !selectedContracts.find((i) => i.id === item.id),
      },
    });
  };
  return (
    <div className={`block ContractFilter`}>
      <div className={"contractsSelectMenu"}>
        {contractList.map((item, i) => (
          <Row
            key={item.id}
            onClick={() => contractRowClicked(item)}
            className={
              selectedContracts && selectedContracts.some((i) => i.id === item.id) ? "selected" : "not-selected"
            }
          >
            <Col span={2} className={"textAlignCenter"}>
              <Checkbox
                value={item}
                checked={selectedContracts && selectedContracts.some((i) => i.id === item.id)}
              ></Checkbox>
            </Col>
            <ContractHeaderContent type={"no-factoring"} key={item.id + i} item={item} />
          </Row>
        ))}
      </div>
    </div>
  );
};

export default ContractFilter;
