import React, { useState } from "react";
import { Spin } from "antd";

import SkIdStart from "../skid/skidStart";
import SkIdConfirm from "../skid/skidConfim";
import { useTranslation } from "react-i18next";
import { productType, getActiveParty, getActiveProduct } from "utils/partiesUtils";
import { getCorrectLanguage } from "services/userService";

const MobileId = (props) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [code, setCode] = useState("");
  const [error, setError] = useState(false);

  const valuesSubmitted = async (values) => {
    try {
      setLoading(true);
      setError(false);
      const postMobileIdAuthenticateRequestResponse = await props.postMobileIdAuthenticateRequest({
        username: values.username,
        userRequest: {
          phoneNumber: values.phoneCode + values.phoneNumber,
          nationalIdentityNumber: values.personalCode,
        },
      });

      setCode(postMobileIdAuthenticateRequestResponse.verificationCode);
      setStep(2);

      setLoading(false);

      const postMobileIdResponse = await props.postMobileIdAuthenticate(postMobileIdAuthenticateRequestResponse);

      await loginUser(postMobileIdResponse);
    } catch (e) {
      console.log(e);
      setStep(1);
      setCode("");
      setError(true);
      setLoading(false);
    }
  };

  const loginUser = async (result) => {
    setLoading(true);

    const userInfo = await props.getUser(result.token);

    result["user"] = {
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      accountId: userInfo.id,
      language: getCorrectLanguage(result.token, userInfo.language),
      country: userInfo.country,
      phone: userInfo.phoneNumber,
      email: userInfo.email,
      termsConditionsAccepted: userInfo.termsConditionsAccepted,
    };

    const userParties = await props.getParties(result.token);
    result["parties"] = userParties;

    const activeParty = getActiveParty(userParties);
    const activeProduct = getActiveProduct(activeParty);

    // party and product fid are equals, only because it's BO workaround
    const boParty = await props.getBoParty(
      activeProduct.type === productType.Factoring.toUpperCase() ? activeProduct?.fid : activeParty?.fid,
      result.token
    );

    result["assignedManager"] = boParty?.assignedManager;

    props.loginSuccess(result);
  };

  const canceled = () => {
    setLoading(true);
    setStep(1);
    setLoading(false);
  };

  return (
    <>
      <Spin spinning={loading} tip={t("loading") + "..."}>
        {step === 1 && (
          <SkIdStart isSmart={false} countries={props.countries} valuesSubmitted={valuesSubmitted} error={error} />
        )}
        {step !== 1 && <SkIdConfirm isSmart={false} code={code} canceled={canceled} />}
      </Spin>
    </>
  );
};

export default MobileId;
