import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import DokobitModal from "components/dokobit/DokobitModal";

import { getDokobitSingingStatus, getFromLocalStorage } from "services/formService";

const DokobitSubmitter = ({ toggle, formContinueCb, formExitCb, submitCallFn }) => {
  const { t } = useTranslation();
  const [dokobitFrameUrl, setDokobitFrameUrl] = useState(null);

  useEffect(() => {
    if (toggle) {
      (async () => {
        const response = await submitCallFn({
          ...getFromLocalStorage(),
          signing: {
            type: "DOKOBIT",
          },
        });
        if (response.ok && response.payload.follow) {
          setDokobitFrameUrl(response.payload.follow);
        } else {
          formContinueCb(t("leasingFormDokobitConfirmationError"));
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle]);

  const onDokobitFrameClose = () => {
    setDokobitFrameUrl(null);
    formContinueCb();
  };

  const onDokobitFrameReload = async () => {
    if (dokobitFrameUrl) {
      try {
        const response = await getDokobitSingingStatus(dokobitFrameUrl.split("/").pop().split("?").shift()); // get token ID
        if (response.ok && response.payload === "completed") {
          formExitCb(true);
        }
      } catch (e) {
        console.error(e);
        // malformed URL for token retrieval?
        formContinueCb(t("leasingFormDokobitSingingError")); // TODO: fix translation id
      }
    }
  };

  return (
    <>
      <DokobitModal onClose={onDokobitFrameClose} onLoad={onDokobitFrameReload} url={dokobitFrameUrl} />
    </>
  );
};

export default DokobitSubmitter;
