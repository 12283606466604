import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "assets/scss/new/components/statusHeader.scss";

const StatusHeader = ({ translationId, status }) => {
  const { t } = useTranslation();

  const [statusStyle, setStatusStyle] = useState();

  useEffect(() => {
    switch (status) {
      case "ACTIVE":
      case "SIGNED":
      case "DISBURSED": {
        setStatusStyle("activeStatus");
        break;
      }
      case "Expired":
      case "FINISHED": {
        setStatusStyle("expiredStatus");
        break;
      }
      case "MANUALLY_SUSPENDED":
      case "CANCELED":
      case "PAUSED": {
        setStatusStyle("suspendedStatus");
        break;
      }
      default: {
        setStatusStyle("defaultStatus");
        break;
      }
    }
  }, [status]);

  return <h2 className={`status ${statusStyle}`}>{t(translationId)}</h2>;
};

export default StatusHeader;
