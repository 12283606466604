import React from "react";
import { Steps } from "antd";

const CustomSteps = ({ className, current, steps }) => {
  const { Step } = Steps;

  return (
    <Steps className={`${className ? className : ""}`} current={current}>
      {steps.map((item) => (
        <Step key={item.key} title={item.title} description={item.description} />
      ))}
    </Steps>
  );
};
export default CustomSteps;
