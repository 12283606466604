import React, { useState } from "react";
import Button from "components/shared/Button";
import { useTranslation } from "react-i18next";

const ContractSignItem = ({ contract, permissionCheck, openModal }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const onClick = async (contract) => {
    setLoading(true);
    await openModal(contract);
    setLoading(false);
  };

  return (
    <div className="contract-sign__item" key={contract.id}>
      <div className="contract-sign__info">
        <span className="contract-sign__number">{contract.contractNumber}</span>
        <p className="contract-sign__label">{t("contractSignCaption")}</p>
      </div>
      {permissionCheck && (
        <Button type="primary" size="small" onClick={() => onClick(contract)} loading={loading}>
          {t("contractSignButton")}
        </Button>
      )}
    </div>
  );
};

export default ContractSignItem;
