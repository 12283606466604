import React from "react";
import { useTranslation } from "react-i18next";

import { Form } from "antd";
import Input from "components/shared/form/Input";
import Row from "components/shared/Row";
import Col from "components/shared/Col";

const capitalize = (s) => {
  return s && s[0].toUpperCase() + s.slice(1);
};

const PipedriveForm = ({ formInstance, onFormFinish, initialValues }) => {
  const { t } = useTranslation();

  const getLabel = (status, type) => {
    if (!status) return;

    const statusLabel = capitalize(status.toLowerCase());

    switch (type) {
      case "product_type":
        return t(`pipedriveFormProductType${statusLabel}`);

      case "status":
        return t(`pipedriveFormStatus${statusLabel}`);

      case "pipeline_status":
        return t(`pipedriveFormPipelineStatus${statusLabel}`);

      default:
        return "";
    }
  };

  const initialFormValues = {
    productType: getLabel(initialValues["product_type"], "product_type"),
    loanAmount: initialValues.value && initialValues.currency && `${initialValues.value} ${initialValues.currency}`,
    applicationStatus: getLabel(initialValues.status, "status"),
    pipedriveStatus: getLabel(initialValues.pipeline_status, "pipeline_status"),
    companyName: initialValues["org_name"],
    name: initialValues["person_id"]?.name,
    phoneNumber: initialValues["person_id"]?.phone[0].value,
    email: initialValues["person_id"]?.email[0].value,
    applicationDate: initialValues.add_time

  };

  return (
    <Form className="form" form={formInstance} onFinish={onFormFinish} initialValues={initialFormValues}>
      <h3 className="invoice-verification-confirmation__header">{t("pipedriveFormProduct")}</h3>
      <Row>
        <Col xs={24} sm={12}>
          <Input
            label={t("pipedriveFormProduct")}
            name="productType"
            disabled
          />
        </Col>
        <Col xs={24} sm={12}>
          <Input
            label={t("pipedriveFormLoanAmount")}
            name="loanAmount"
            disabled
          />
        </Col>
      </Row>

      <h3 className="invoice-verification-confirmation__header">{t("pipedriveFormStatus")}</h3>

      <Row>
        <Col xs={24} sm={12}>
          <Input
            label={t("pipedriveFormApplicationStatus")}
            name="applicationStatus"
            disabled
          />
        </Col>
        <Col xs={24} sm={12}>
          <Input
            label={t("pipedriveFormPipelineStatus")}
            name="pipedriveStatus"
            disabled
          />
        </Col>
      </Row>

      <h3 className="invoice-verification-confirmation__header">{t("pipedriveFormInformation")}</h3>
      <Row>
        <Col span={24}>
          <Input
            label={t("pipedriveFormCompanyName")}
            name="companyName"
            disabled
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Input
            label={t("pipedriveFormClientName")}
            name="name"
            disabled
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={12}>
          <Input
            label={t("pipedriveFormPhoneNumber")}
            name="phoneNumber"
            disabled
          />
        </Col>
        <Col xs={24} sm={12}>
          <Input
            label={t("pipedriveFormEmail")}
            name="email"
            disabled
          />
        </Col>
      </Row>

      <h3 className="invoice-verification-confirmation__header">{t("pipedriveFormLoan")}</h3>
      <Row>
        <Col span={24}>
          <Input
            label={t("pipedriveFormApplicationDate")}
            name="applicationDate"
            disabled
          />
        </Col>
      </Row>
    </Form>
  );
};

export default PipedriveForm;
