import React, { useState, createContext, useEffect } from "react";
import { getCountriesWithPhoneCodes } from "../services/accountService";

export const UserContext = createContext(); // Create a context object

export const countries = [
  {
    code: "EE",
    dial_code: "+372",
    latitude: 59,
    longitude: 26,
    name: "Estonia",
  },
  {
    code: "LV",
    dial_code: "+371",
    latitude: 57,
    longitude: 25,
    name: "Latvia",
  },
  {
    code: "LT",
    dial_code: "+370",
    latitude: 56,
    longitude: 24,
    name: "Lithuania",
  },
];

export const UserProvider = ({ children }) => {
  const [countriesPhoneCodes, setCountiresPhoneCodes] = useState(null);

  useEffect(() => {
    const fetchPhoneCodes = async () => {
      const countriesWithPhoneCodesResponse = await getCountriesWithPhoneCodes();

      const countriesWithPhoneCodes = countriesWithPhoneCodesResponse;

      countriesWithPhoneCodes.sort((a, b) =>
        a.dial_code > b.dial_code ? 1 : -1
      );

      setCountiresPhoneCodes(countriesWithPhoneCodes);
    };
    if (countriesPhoneCodes === null) {
      fetchPhoneCodes();
    }
  });

  return (
    <UserContext.Provider value={{ countriesPhoneCodes }}>
      {children}
    </UserContext.Provider>
  );
};
